import { useEffect, useState } from "react";
import { MDBAccordion, MDBAccordionItem, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import axios from "axios";
import { configHeader } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import CurrentLogTimeStatus from "./CurrentLogTimeStatus";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import LogWork from "./LogWork";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import UpdateSubTask from "../../modals/UpdateSubTask";
import DisplayTaskProcessing from "../Planning/DisplayTaskProcessing";
import ViewBoardItem from "../TaskInfo/ViewBoardItem";
import { defaultBoxMinWidth, defaultBoxWidth, userRoles } from "../../utils/utilities";
import CtrlDisplayExtentDetail from "../../components/CtrlDisplayExtentDetail";
import DisplayOverdue from "../../components/DisplayOverdue";
import DisplayMultiRows from "../../components/DisplayMultiRows";
import ChangeMemberSubTask from "../../modals/ChangeMemberSubTask";

function CellSubTaskSection({
  colIndex,
  taskIndex,
  cellInfo,
  userRole,
  staffs,
  staffsOfPhase,
  handleDeleteSubTask,
  taskInfoId,
  reloadTaskInfo,
  colWidth,
  handleViewWidth
}) {

  const subTaskInfo = cellInfo ?? {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogTimeOpen, setIsLogTimeOpen] = useState(false);
  const [taskDetailInfo, setTaskDetailInfo] = useState({});
  const [isOpenSubTaskModal, setIsOpenSubTaskModal] = useState(false);
  const [isChangeMember, setIsChangeMember] = useState(false);
  const [userTeamId, setUserTeamId] = useState();
  const [userId, setUserId] = useState();
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [modalWidth, setModalWidth] = useState(colWidth);

  useEffect(() => {
    setModalWidth(colWidth);
  }, [colWidth]);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
    setUserId(infoObj.id);
  }, []);

  useEffect(() => {
    reloadTaskDetailInfo(taskInfoId);
    reloadTaskInfo(taskInfoId);
  }, [taskInfoId]);

  const reloadTaskDetailInfo = (taskId) => {
    taskId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskId}`,
          configHeader()
        )
        .then((result) => {
          setTaskDetailInfo(result?.data);
        });
  };

  const saveData = (data) => {
    subTaskInfo?.id &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${subTaskInfo.id}/LogWork`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskInfo(taskInfoId);
          reloadTaskDetailInfo(taskInfoId);
          setIsLogTimeOpen(false);
          setIsModalOpen(false);
        });
  };

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  const onSaveSubTask = (data) => {
    taskInfoId &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskInfoId}/Subtask`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskInfo(taskInfoId);
          setIsOpenSubTaskModal(false);
        });
  };

  const onUpdateSubTask = (id, data) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/Subtasks/${id}`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskInfo(taskInfoId);
          setIsModalOpen(false);
        });
  }

  const onUpdateAssignerSubTask = (id, assignId) => {
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/Subtasks/${id}/AssignTo/${assignId}`,
          {},
          configHeader()
        )
        .then(() => {
          reloadTaskInfo(taskInfoId);
          setIsChangeMember(false);
        });
  }

  const renderCtrDelete = () => {
    let mySubTask = userId === subTaskInfo?.assignedId;
    if (subTaskInfo?.isPM || userRole === userRoles.Admin || mySubTask) {
      return (
        <MDBRow>
          <MDBCol size="3" sm="12" title="Delete this subtask">
            <div
              style={{ alignItems: "flex-end" }}
              onClick={() => {
                setIsConfirmDelete(true);
              }}
            >
              <MDBIcon fas icon="trash-alt" color="warning" />
            </div>
          </MDBCol>
        </MDBRow>
      );
    }

    return <></>;
  };

  const renderLogWork = () => {
    let mySubTask = userId === subTaskInfo?.assignedId;
    let hasTeam = (taskDetailInfo?.teamIds ?? []).filter((item) => +userTeamId === +item)
      ?.length > 0;

    if (subTaskInfo?.isCompleted || subTaskInfo?.isFinished || subTaskInfo?.statusName === "Done") return <></>;
    if (subTaskInfo?.isPM || userRole === userRoles.Admin || mySubTask || hasTeam) {
      return renderControlLogTimeByUser();
    }

    return <></>;
  };

  const renderControlLogTimeByUser = () => {
    return (<MDBRow>
      <MDBCol sm="8">
        <div onClick={() => setIsLogTimeOpen(true)}>
          Log Working
        </div>
      </MDBCol>
      <MDBCol sm="4" title={`Change ${subTaskInfo?.name} to other member.`}>
        <MDBIcon fas icon="retweet" onClick={() => setIsChangeMember(true)} />
      </MDBCol>
    </MDBRow>
    );
  }

  const handleMinTaskDetail = () => {
    setModalWidth(defaultBoxMinWidth);
  }

  const handleViewTaskDetail = () => {
    setInitialItem(1);
    handleViewWidth();
    setModalWidth(defaultBoxWidth);
  }

  const renderTitleSubTaskDisplayInfo = () => {
    if ((modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) {
      return <MDBRow>
        <MDBCol sm="6" className={` truncate max-w-[${colWidth ?? 245}px] `} title={subTaskInfo?.description}>
          <div style={{ marginTop: -5 }}>
            <DisplayTaskProcessing
              statusName={subTaskInfo?.statusName}
              planHours={subTaskInfo?.estimateHours}
              workingHours={subTaskInfo?.normalHours ?? 0}
              otHours={subTaskInfo?.overTimeHours ?? 0}
              displayOverHours
            />
          </div>
        </MDBCol>
        <MDBCol sm="2">
          <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => setIsModalOpen(true)}
            title={`Update subtask information: ${subTaskInfo?.name}`}
          >
            <MDBIcon fas icon="user-edit" />
          </div>
        </MDBCol>
        <MDBCol sm="2">{renderCtrDelete()}</MDBCol>
        <MDBCol sm="2">
          <CtrlDisplayExtentDetail
            tabWidth={modalWidth}
            handleViewTaskDetail={() => handleViewTaskDetail()}
            handleMinTaskDetail={() => handleMinTaskDetail()}
          /></MDBCol>
      </MDBRow>
    }

    return <MDBRow>
      <MDBCol sm="9" className={` truncate max-w-[${modalWidth ?? 245}px] `} title={subTaskInfo?.description}>
        <div style={{ marginTop: -5 }}>
          <DisplayTaskProcessing
            statusName={subTaskInfo?.statusName}
            planHours={subTaskInfo?.estimateHours}
            workingHours={subTaskInfo?.normalHours ?? 0}
            otHours={subTaskInfo?.overTimeHours ?? 0}
            displayOverHours
          />
        </div>
      </MDBCol>
      <MDBCol sm="1">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsModalOpen(true)}
          title={`Update subtask information: ${subTaskInfo?.name}`}
        >
          <MDBIcon fas icon="user-edit" />
        </div>
      </MDBCol>
      <MDBCol sm="1">{renderCtrDelete()}</MDBCol>
      <MDBCol sm="1">
        <CtrlDisplayExtentDetail
          tabWidth={modalWidth}
          handleViewTaskDetail={() => handleViewTaskDetail()}
          handleMinTaskDetail={() => handleMinTaskDetail()}
        /></MDBCol>
    </MDBRow>
  }

  const renderCurrentDescription = () => {
    if (modalWidth !== defaultBoxWidth) return <></>
    return <DisplayMultiRows description={subTaskInfo?.description ?? ""} />
  }

  const renderCurrentStatus = () => {
    if (modalWidth !== defaultBoxWidth) return <></>

    return <>
      <div style={{ marginRight: 25 }}></div>
      <CurrentLogTimeStatus
        statusName={subTaskInfo?.statusName}
        estimateHours={subTaskInfo?.estimateHours}
        normalHours={subTaskInfo?.normalHours ?? 0}
        overTime={subTaskInfo?.overTimeHours ?? 0}
        startDate={subTaskInfo?.startDate}
        endDate={subTaskInfo?.endDate}
        hideStatus
        hideDate
      />
    </>
  }

  const renderCtrlSubTaskAccordion = () => {
    let initId = (modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth ? 0 : initialItem;
    return <MDBAccordion alwaysOpen initialActive={initId}>
      <MDBAccordionItem collapseId={1}
        headerTitle={<>
          <DisplayOverdue
            statusName={subTaskInfo?.statusName ?? ""}
            endDate={subTaskInfo?.endDate}
          />

          <div style={{ marginLeft: 20 }} className={` truncate max-w-[${modalWidth ?? 275}px] `}>{subTaskInfo?.name}</div>

          {renderCurrentStatus()}

        </>}
        className={` truncate max-w-[${modalWidth ?? 275}px] `} title={subTaskInfo?.name}>
        <p
          className=" font-bold tracking-wide "
          title={subTaskInfo?.description}
        >
          {renderTitleSubTaskDisplayInfo()}
        </p>

        <MDBAccordion borderless alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="Subtask info" title={subTaskInfo?.description}>
            {modalWidth === defaultBoxMinWidth && <CurrentLogTimeStatus
              statusName={subTaskInfo?.statusName}
              estimateHours={subTaskInfo?.estimateHours}
              normalHours={subTaskInfo?.normalHours ?? 0}
              overTime={subTaskInfo?.overTimeHours ?? 0}
              startDate={subTaskInfo?.startDate}
              endDate={subTaskInfo?.endDate}
              assigned={subTaskInfo?.assigned}
              description={subTaskInfo?.description}
              hideStatus
            />}
            {renderCurrentDescription()}
          </MDBAccordionItem>
          {renderLogWork()}
        </MDBAccordion>
      </MDBAccordionItem>
    </MDBAccordion>
  }

  const [initialItem, setInitialItem] = useState(0);

  return (
    <div>
      {isConfirmDelete && subTaskInfo?.id && (
        <ConfirmDeleteDialog
          openModal={true}
          titleDelete={`Delete subtask ${subTaskInfo?.name}`}
          onCloseModal={() => setIsConfirmDelete(false)}
          onDelete={() => {
            handleDeleteSubTask(subTaskInfo?.id);
            setIsConfirmDelete(false);
          }}
        />
      )}

      <ViewBoardItem
        ctrlAccordion={renderCtrlSubTaskAccordion()}
        id={`sub-${colIndex}-${subTaskInfo?.id}`}
        handleOnDrag={handleOnDrag}
        colWidth={colWidth}
      />

      {isLogTimeOpen && (
        <LogWork
          taskInfo={subTaskInfo}
          subTasks={[subTaskInfo]}
          onCloseModal={() => setIsLogTimeOpen(false)}
          saveData={(data) => saveData(data)}
        />
      )}

      {isModalOpen && (
        <UpdateSubTask
          taskId={taskInfoId}
          teamId={userTeamId}
          taskName={cellInfo?.name}
          isPM={cellInfo?.isPM}
          subTaskInfo={subTaskInfo}
          onCloseModal={() => setIsModalOpen(false)}
          onSave={(data) => onUpdateSubTask(cellInfo?.id, data)}
        />
      )}

      {isOpenSubTaskModal && (
        <CreateNewSubTask
          taskId={taskInfoId}
          teamId={userTeamId}
          taskName={cellInfo?.name}
          isPM={cellInfo?.isPM}
          staffs={staffsOfPhase ?? []}
          onCloseModal={() => setIsOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}

      {isChangeMember && <ChangeMemberSubTask
        staffs={staffs}
        subTaskName={cellInfo?.name}
        subTaskInfo={subTaskInfo}
        teamId={userTeamId}
        onSave={onUpdateAssignerSubTask}
        onCloseModal={() => setIsChangeMember(false)} />}

    </div>
  );
}

export default CellSubTaskSection;
