import { MDBCol, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

function AssignTaskToTeam({ info, handleUseTeam, canModify, isChecked }) {
  const [showChecked, setShowChecked] = useState(isChecked);

  useEffect(() => {
    setShowChecked(isChecked);
  }, [isChecked]);

  const handleCheckTeam = (checked) => {
    setShowChecked(checked)
    handleUseTeam(info?.id, checked);
  }

  return (
    <MDBRow>
      <MDBCol size="3" sm="2">
        <MDBSwitch
          id={uuidv4()}
          readOnly={!canModify}
          checked={showChecked}
          onChange={(e) => handleCheckTeam(e.target.checked)}
        />
      </MDBCol>
      <MDBCol size="3" sm="9" style={{ textAlign: "left" }}>
        {info?.name}
      </MDBCol>
    </MDBRow>
  );
}

export default AssignTaskToTeam;
