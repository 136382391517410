import { AgGridReact } from "ag-grid-react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onGridReady } from "../../utils/utilities";
import axios from "axios";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy } from "lodash";
import CreateNewCompany from "./CreateNewCompany";
import EditCompany from "./EditCompany";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Company() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState();
  const [client, setClient] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [minRow, setMinRow] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "List of Customers";
    reloadCustomers();
  }, []);

  const reloadCustomers = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.companyUrl}`,
        configHeader()
      )
      .then((result) => {
        setCustomers(result?.data ?? []);
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "name",
      pinned: 'left',
      width: 235,
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      filter: true
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "director",
      headerName: "Director",
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "bankAccount",
      headerName: "Bank Account",
      cellStyle: {
        textAlign: "left",
      },
      filter: true
    },
    {
      field: "address",
      headerName: "Address",
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
      width: 295,
      filter: true
    },
    {
      field: "actions",
      headerName: "",
      pinned: 'right',
      lockPinned: true,
      width: 50,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="edit"
            onClick={() => handleEditCustomer(row.data)}
          />
        );
      },
    }
  ];

  const handleEditCustomer = (customer) => {
    setClient(customer);
    setOpenUpdateModal(true);
  }

  const saveData = (data) => {
    client?.id && axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.companyUrl}/${client?.id}`,
        data,
        configHeader()
      )
      .then(() => {
        setOpenUpdateModal(false);
        reloadCustomers();
        toast.success("Your data has been updated.");
      });
  }

  return <>
    <MDBCard>
      <MDBCardBody>
        {openCreateModal && <CreateNewCompany onCloseModal={() => setOpenCreateModal(false)} />}
        {openUpdateModal && <EditCompany
          data={client}
          onCloseModal={() => setOpenUpdateModal(false)}
          saveData={(data) => saveData(data)}
        />}

        <MDBRow>
          <MDBCol sm="6">
            <MDBBtn onClick={() => setOpenCreateModal(true)}>
              <MDBIcon fas icon="plus" /> New a Customer
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <div className="ag-theme-quartz" style={{ height: "65vh", marginTop: 25 }}>
          <AgGridReact
            rowData={orderBy(customers, "name")}
            columnDefs={colDefs}
            fullWidthCellRenderer
            onGridReady={onGridReady}
          />
        </div>
      </MDBCardBody>
    </MDBCard>
    <ToastContainer />
  </>;
}

export default Company;
