import { orderBy, shuffle } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CellSection from "./CellSection";
import CellPhaseSection from "./CellPhaseSection";
import CellSubTaskSection from "./CellSubTaskSection";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { defaultBoxMinWidth } from "../../utils/utilities";
import "./Project.css";

function ColumnSection({
  columnInfo,
  colIndex,
  statusOfPhase,
  isProject,
  taskInfoId,
  teams,
  staffs,
  userRole,
  staffsOfPhase,
  projectResources,
  dragTask,
  onSavePhase,
  handleAddSubtask,
  handleUpdateSubtask,
  onSaveTask,
  handleDelete,
  onUpdateTask,
  handleDeleteTask,
  onClonePhase,
  handleDeleteSubTask,
  currentPhaseId,
  projectId,
  reloadPhaseInfo,
  handleLogWork,
  reloadTaskInfo,
  onAddRevisionTask,
  colWidth,
  handleViewWidth,
  handleMinView,
  handleCreatePhaseClick,
  handleCreateTaskClick,
  handleCreateSubTaskClick,
  handleSavePhaseResource
}) {
  const colors = [
    "bg-red-500",
    "bg-orange-500",
    "bg-blue-500",
    "bg-purple-500",
    "bg-green-500",
    "bg-indigo-500",
    "bg-yellow-500",
    "bg-pink-500",
    "bg-sky-500",
  ];

  const dispatch = useDispatch();
  const [color, setColor] = useState(null);
  const [entities, setEntities] = useState(columnInfo?.entities ?? []);
  // const [colStatusName, setColStatusName] = useState();
  // const [isSubTask, setIsSubTask] = useState(false);
  // const [isTask, setIsTask] = useState(false);
  // const [isPhase, setIsPhase] = useState(false);

  useEffect(() => {
    setEntities(columnInfo?.entities ?? []);
  }, [columnInfo]);

  useEffect(() => {
    setColor(shuffle(colors).pop());
  }, [dispatch]);

  const handleOnDrop = (e) => {
    const { prevColIndex, taskIndex } = JSON.parse(
      e.dataTransfer.getData("text")
    );

    if (colIndex !== prevColIndex) {
      dragTask(colIndex, prevColIndex, taskIndex);
    }
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  const handleAddItemClick = (stsName) => {
    if (taskInfoId) return handleCreateSubTaskClick(stsName, projectResources);
    if (isProject) return handleCreatePhaseClick(stsName);
    return handleCreateTaskClick(stsName);
  }

  const renderControlAddItem = (stsName) => {
    if (stsName === "Done") return <></>
    return <MDBIcon
      fas
      icon="plus"
      color="primary"
      style={{ cursor: "pointer", alignItems: "flex-end", marginLeft: 20 }}
      onClick={() => handleAddItemClick(stsName)}
      title={`Add new item ${stsName}`}
    />
  }

  const renderTitle = () => {
    if ((entities ?? []).length < 1) {
      return (
        <p className=" font-semibold flex  items-center  gap-2 tracking-widest md:tracking-[.2em] text-[#828fa3]">
          <div className={`rounded-full w-3 h-4 ${color} `} />
          <div className=" truncate max-w-[200px]" title={columnInfo?.status}> {columnInfo?.status}</div>
          {renderControlAddItem(columnInfo?.status)}
        </p>
      );
    }

    return (
      <>
        <p className=" font-semibold flex  items-center  gap-2 tracking-widest md:tracking-[.2em] text-[#828fa3]">
          {renderIconView()}
          <div className=" truncate max-w-[300px]" title={columnInfo?.status}> {columnInfo?.status} </div> ({entities?.length})
          {renderControlAddItem(columnInfo?.status)}
        </p>
      </>
    );
  };

  const renderIconView = () => {
    if ((colWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) return <div className={`rounded-full w-3 h-4 ${color} `} />
    return <MDBIcon fas icon="minus-square" onClick={() => handleMinView()} style={{ marginRight: 20 }} />
  }

  const renderCellSectionInfo = (entity, index, colWidth) => {
    if (taskInfoId > 0) {
      return (
        <CellSubTaskSection
          key={index}
          taskIndex={index}
          colIndex={colIndex}
          cellInfo={entity}
          statusOfPhase={statusOfPhase}
          teams={teams}
          userRole={userRole}
          staffs={staffs}
          staffsOfPhase={entity?.staffs ?? staffsOfPhase ?? projectResources}
          handleAddSubtask={handleAddSubtask}
          handleUpdateSubtask={handleUpdateSubtask}
          onUpdateTask={onUpdateTask}
          handleDeleteSubTask={handleDeleteSubTask}
          reloadPhaseInfo={reloadPhaseInfo}
          currentPhaseId={currentPhaseId}
          taskInfoId={taskInfoId}
          reloadTaskInfo={reloadTaskInfo}
          colWidth={colWidth}
          handleViewWidth={handleViewWidth}
        />
      );
    }

    if (isProject) {
      if (!entity?.isMonitoring) return <></>
      return (
        <CellPhaseSection
          key={index}
          taskIndex={index}
          colIndex={colIndex}
          cellInfo={entity}
          onSave={onSavePhase}
          userRole={userRole}
          statusOfPhase={statusOfPhase}
          teams={teams}
          staffs={staffs}
          projectResources={projectResources}
          onSaveTask={onSaveTask}
          handleDelete={handleDelete}
          onClonePhase={onClonePhase}
          colWidth={colWidth}
          handleViewWidth={handleViewWidth}
          handleSavePhaseResource={handleSavePhaseResource}
        />
      );
    }

    return (
      <CellSection
        key={index}
        taskIndex={index}
        colIndex={colIndex}
        cellInfo={entity}
        statusOfPhase={statusOfPhase}
        teams={teams}
        userRole={userRole}
        staffsOfPhase={staffsOfPhase}
        projectResources={projectResources}
        handleAddSubtask={handleAddSubtask}
        handleUpdateSubtask={handleUpdateSubtask}
        onUpdateTask={onUpdateTask}
        handleDeleteTask={handleDeleteTask}
        reloadPhaseInfo={reloadPhaseInfo}
        currentPhaseId={currentPhaseId}
        handleLogWork={handleLogWork}
        onAddRevisionTask={onAddRevisionTask}
        colWidth={colWidth}
        handleViewWidth={handleViewWidth}
      />
    );
  };

  return (
    <>
      <div
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
        className={`scrollbar-hide mx-2 pt-[24px] min-w-[${colWidth ?? defaultBoxMinWidth}px] `}
        style={{ width: colWidth ?? defaultBoxMinWidth }}
      >
        {renderTitle()}
        <div className="divScroll90vh">
          {orderBy(entities ?? [], "priorityNumber").map((entity, index) => {
            return <MDBRow style={{ padding: 1, paddingRight: 10 }}>
              <MDBCol size="3" sm="12">
                {renderCellSectionInfo(entity, index, colWidth)}
              </MDBCol>
            </MDBRow>
          })}
        </div>
      </div>
    </>
  );
}

export default ColumnSection;
