import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { difference, first } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import UpdateDisplayName from "../Planning/UpdateDisplayName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onGridReady } from "../../utils/utilities";
import ViewUserInfo from "../Users/ViewUserInfo";

function TeamMembers() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState();
  const [viewStaffs, setViewStaffs] = useState();
  const [curStaffs, setCurStaffs] = useState();
  const [employees, setEmployees] = useState();
  const [teams, setTeams] = useState();
  const [teamId, setTeamId] = useState();
  const [teamleader, setTeamleader] = useState("");
  const [userId, setUserId] = useState();
  const [displayName, setDisplayName] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);
  // const [userRole, setUserRole] = useState();
  // const [userTeamId, setUserTeamId] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Manage teams";
    // var info = window.localStorage.getItem("userInfo") ?? "";
    // let infoObj = JSON.parse(info);
    // setUserRole(infoObj.role);
    // setUserTeamId(toNumber(infoObj.teamId ?? 0) ?? 0);
  }, []);

  useEffect(() => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/All-Teams`,
        configHeader()
      )
      .then((result) => {
        setTeams((result?.data ?? []).filter((item) => item.isActive));
      });
  }, []);

  useEffect(() => {
    loadDataStaffs();
  }, []);

  const loadDataStaffs = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/All-Users`,
        configHeader()
      )
      .then((result) => {
        let items = filterStaffs(result?.data, []);
        setStaffs(items ?? []);
        setViewStaffs(items ?? []);
      });
  };

  const handleEditName = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setOpenChangeNameModal(true);
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        loadDataStaffs();
        setOpenChangeNameModal(false);
      });
  };

  const handleEditLeader = (id, name) => {
    teamId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Teams/${teamId}/UpdateLeader/${id}`,
          {},
          configHeader()
        )
        .then(() => {
          setTeamleader(name);
          toast.success(
            `Your request change teamleader was successful. Current team leader is ${name}`
          );
        });
  };

  const colDefs = [
    // {
    //   headerName: "#",
    //   valueGetter: "node.rowIndex + 1",
    //   width: 65,
    // },
    {
      field: "displayName",
      filter: true,
      width: 195,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => <ViewUserInfo roleName={row.data.roleName} isActive={row.data.isActive} displayName={row.data.displayName} />
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 145,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
      width: 175,
      filter: true
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
      filter: true
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="user-edit"
            title="Change display name"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    },
  ];

  const columDefs = [
    // {
    //   headerName: "Row",
    //   valueGetter: "node.rowIndex + 1",
    //   width: 75,
    // },
    {
      field: "displayName",
      width: 250,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => <ViewUserInfo roleName={row.data.roleName} isActive={row.data.isActive} displayName={row.data.displayName} />
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 145,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      width: 235,
      filter: true
    },
    {
      field: "actions",
      headerName: "",
      width: 80,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <>
            <MDBIcon
              fas
              icon="user-circle"
              title="Change team leader"
              style={{ cursor: "pointer", marginRight: 20 }}
              onClick={() => handleEditLeader(row.data.id, row.data.displayName)}
            />
            <MDBIcon
              fas
              icon="trash-alt"
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => handleRemove(row.data.id)}
            />
          </>
        );
      },
    },
  ];

  const handleRemove = (id) => {
    let rs = (employees ?? []).filter((s) => s.id !== id);
    let items = filterStaffs(staffs, rs);
    setViewStaffs(items ?? [])
    setEmployees(rs);
  };

  const onSelectionChanged = (event) => {
    const selectedData = event.api.getSelectedRows();
    setCurStaffs(selectedData);
  };

  const handleSelect = () => {
    let rs = (employees ?? []).concat(curStaffs ?? []);
    let items = filterStaffs(staffs, rs);
    setViewStaffs(items ?? [])
    setEmployees(rs);
  };

  const filterStaffs = (items, others) => {
    let rs = (items ?? []).filter((item) => item.isActive);
    return difference(rs, others);
  };

  const options = () => {
    return (teams ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (teams ?? []).filter((p) => ids.some((id) => id === p.id));
    let team = first(rs) ?? {};
    setTeamleader(team?.teamleader ?? "N/A");
    setTeamId(team?.id);
  };

  useEffect(() => {
    reloadTeamInfo(teamId);
  }, [teamId]);

  const reloadTeamInfo = (teamId) => {
    let items = (staffs ?? []).filter((item) => item.teamId === teamId);
    loadDataInitByTeam(items);
  };

  const loadDataInitByTeam = (listStaffs) => {
    let ids = (listStaffs ?? []).map((it) => it.id);
    let rs = (staffs ?? []).filter((p) => (ids ?? []).some((id) => id === p.id));
    let items = filterStaffs(staffs, rs);
    setViewStaffs(items ?? [])
    setEmployees(rs);
  };

  const saveData = () => {
    let staffIds = (employees ?? []).map((item) => item.id);
    teamId && axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Teams/${teamId}/Staffs`,
        {
          staffIds: staffIds,
        },
        configHeader()
      )
      .then(() => {
        setEmployees([]);
        loadDataStaffs();
        reloadTeamInfo(teamId);
        toast.success(`The team has been changed members`);
      });
  };

  return (
    <>
      {openChangeNameModal && (
        <UpdateDisplayName
          setIsOpenModal={setOpenChangeNameModal}
          displayName={displayName}
          setDisplayName={setDisplayName}
          updateDisplayName={updateDisplayName}
        />
      )}
      <MDBRow style={{ marginTop: 10 }}>
        <MDBCol size="12" sm="5">
          <div className="ag-theme-quartz" style={{ height: "65vh" }}>
            <AgGridReact
              rowData={viewStaffs}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
            />
          </div>
        </MDBCol>
        <MDBCol size="12" sm="1">
          <MDBBtn
            type="submit"
            block
            className="mb-4"
            style={{ cursor: "pointer", marginTop: 75 }}
            onClick={() => handleSelect()}
          >
            <MDBIcon fas icon="chevron-circle-right" />
          </MDBBtn>
        </MDBCol>

        <MDBCol size="3" sm="6">
          <MDBRow>
            <MDBCol size="3" sm="10">
              <Multiselect
                options={options()}
                singleSelect
                placeholder="Select a team"
                onSelect={(items) => onSelectedValues(items)}
                onRemove={(items) => onSelectedValues(items)}
                displayValue="name"
              />
            </MDBCol>

          </MDBRow>
          <MDBRow>
            <MDBCol sm="12" style={{ textAlign: "left" }}>
              <div>Team leader : {teamleader?.length > 0 ? teamleader : " TBD"}</div>
            </MDBCol>
          </MDBRow>


          <MDBRow>
            <MDBCol size="6" sm="12">
              <div
                className="ag-theme-quartz"
                style={{ height: "50vh", marginTop: 20 }}
              >
                {employees && (
                  <AgGridReact rowData={employees ?? []} columnDefs={columDefs} onGridReady={onGridReady} />
                )}
              </div>
            </MDBCol>
          </MDBRow>

          <MDBBtn
            type="submit"
            block
            className="btn btn-outline-primary"
            style={{ cursor: "pointer", marginTop: 15 }}
            disabled={(teamId ?? 0) < 1}
            onClick={() => saveData()}
          >
            <MDBIcon fas icon="save" /> Save resources to team
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <ToastContainer />
    </>
  );
}

export default TeamMembers;
