import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StatusList from "./StatusList";
import TeamList from "./TeamList";
import ReceiptSettings from "./ReceiptSettings";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy } from "lodash";
import ViewProjectInfo from "./ViewProjectInfo";
import "./User.css";
import { userRoles } from "../../utils/utilities";

function UserSettings() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState();
  const [projects, setProjects] = useState();
  const [passwordDefault, setPasswordDefault] = useState("");
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "User settings";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    reloadProjects();
    reloadPasswordDefault();
  }, []);

  const reloadPasswordDefault = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/Default-PassWord`,
        configHeader()
      )
      .then((result) => {
        setPasswordDefault(result?.data ?? "");
      });
  };

  const reloadProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = result?.data ?? [];
        setProjects(orderBy(items, "priorityNumber"));
      });
  };


  const handleCheckPhase = (phaseId, checked) => {
    let endPoint = checked ? "Monitoring" : "Ignore";
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/${endPoint}`, {},
        configHeader()
      )
      .then(() => {
        reloadProjects()
      });
  }

  const updatePassWord = () => {
    if (passwordDefault?.length < 8) {
      toast.error("password have at least 8 characters.");
      return;
    } else {
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/Default-PassWord`,
          {
            defaultPass: passwordDefault,
          },
          configHeader()
        )
        .then(() => {
          toast.success("Default password has been successfully changed");
        });
    }
  }

  const renderSystemSettings = () => {
    if (userRole !== userRoles.Admin) {
      return <></>;
    }

    return (
      <>
        <MDBAccordion initialActive={0}>
          <MDBAccordionItem collapseId={1} headerTitle={"Statuses"}>
            <StatusList />
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle={"Teams"}>
            <TeamList />
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle={"Default password "}>
            <MDBRow>
              <MDBCol size="3" sm="3">
                Default password
              </MDBCol>
              <MDBCol size="3" sm="3">
                <MDBInput
                  className="mb-3"
                  type={showPass ? "text" : "password"}
                  label="Default password"
                  title="Password will be applied when admin reset user password"
                  value={passwordDefault}
                  onChange={(evt) => setPasswordDefault(evt.target.value)}
                />
              </MDBCol>
              <MDBCol size="3" sm="1">
                {showPass && (
                  <MDBIcon
                    fas
                    icon="eye-slash"
                    style={{ marginTop: 5 }}
                    onClick={() => setShowPass(false)}
                  />
                )}
                {!showPass && (
                  <MDBIcon
                    fas
                    icon="eye"
                    style={{ marginTop: 5 }}
                    onClick={() => setShowPass(true)}
                  />
                )}
              </MDBCol>
              <MDBCol size="3" sm="2">
                <MDBBtn onClick={() => updatePassWord()}>
                  <MDBIcon fas icon="key" /> Save
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={4} headerTitle={"Receipt"}>
            <ReceiptSettings />
          </MDBAccordionItem>
        </MDBAccordion>
      </>
    );
  };

  const renderProjects = () => {
    {
      (projects ?? []).map((pro) => {
        return <>
          <ViewProjectInfo data={pro}
            handleCheckPhase={(phaseId, checked) => handleCheckPhase(phaseId, checked)} />
        </>
      }
      )
    }

    return <MDBAccordion alwaysOpen initialActive={1}>
      {(projects ?? []).map((pro) => {
        return <MDBAccordionItem collapseId={pro?.id} headerTitle={pro?.name}>
          <ViewProjectInfo data={pro}
            handleCheckPhase={(phaseId, checked) => handleCheckPhase(phaseId, checked)} />
        </MDBAccordionItem>
      })}
    </MDBAccordion>
  }

  const renderBody = () => {
    if (userRole !== userRoles.Admin) return <> {renderProjects()}</>

    return <MDBAccordion alwaysOpen initialActive={1}>
      <MDBAccordionItem collapseId={2} headerTitle="System settings">
        {renderSystemSettings()}
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={1} headerTitle="Project monitoring">
        {renderProjects()}
      </MDBAccordionItem>
    </MDBAccordion>
  }

  return (
    <>
      {renderBody()}
      <ToastContainer />
    </>
  );
}

export default UserSettings;
