import { useEffect, useState } from "react";
import Menu from "./Menu";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HubConnectionBuilder } from "@microsoft/signalr";

const Layout = ({ children }) => {

  const [myUserId, setMyUserId] = useState(0);
  const [myTeamId, setMyTeamId] = useState(0);
  const [allNotify, setAllNotify] = useState([]);
  const [preTitle, setPreTitle] = useState(document.title ?? "");
  const [newTitle, setNewTitle] = useState("");

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setMyTeamId(+infoObj.teamId);
    setMyUserId(+infoObj.id);
    setPreTitle(document.title ?? "");
  }, []);

  useEffect(() => {
    let i = 0;
    let newMsg = `New Messages`
    const interval = setInterval(() => {
      if (document.visibilityState === "visible") {
        const color = Math.floor(Math.random() * 16777215).toString(16);
        document.body.style.background = color;
        if (document.title === preTitle || document.title?.length < 1) {
          document.title = newMsg;
        } else {
          document.title = "";
        }

      } else {
        if (document.title === preTitle) {
          document.title = newMsg;
        } else {
          document.title = preTitle;
        }
      }

      let src = i % 2 === 0 ? 'icon-01.ico' : 'icon-02.ico';
      let srcColor = i % 2 === 0 ? 'red' : 'green';
      let link = document.createElement('link');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = src;
      //document.title.backgroundColor = srcColor;
      //console.log("document", document)
      //document.header.style.backgroundColor = "yellow";
      //document.bgColor = "darkblue";
      //document.head.background = "red"
      if (i === 29) {
        document.title = preTitle;
        link.href = "favicon.ico";
        clearInterval(interval);
      }
      i++;
      document.head.appendChild(link);
    }, 500);
  }, [newTitle, preTitle])

  useEffect(() => {
    let accessToken = window.localStorage.getItem("accessToken") ?? "";
    const connection = new HubConnectionBuilder()
      .withUrl(`${apiEndpoint.hosting}/notifications`, {
        accessTokenFactory: () => accessToken,
        withCredentials: false,
      })
      .withAutomaticReconnect([2000, 5000, 10000, 60000])
      .build();

    // start connection
    connection.start()
      .then(() => {
        console.log("Connection started")
      })
      .catch(err => console.log(err));;

    connection.on("SendNotificationToUser",
      (userId, message, data) => {
        setNewTitle(`${new Date()}`);
        handleSendNotificationToUser(userId, message, data);
      });

    connection.on("SendNotificationToUsers",
      (userIds, message, data) => {
        setNewTitle(`${new Date()}`);
        handleSendNotificationToUsers(userIds, message, data);
      });

    connection.on("SendNotificationToTeam",
      (teamId, message, data) => {
        setNewTitle(`${new Date()}`);
        handleSendNotificationToTeam(teamId, message, data);
      });
  }, []);

  const showMsg = (msg) => {
    if ((msg ?? "").length > 0) {
      let hasNotify = (allNotify ?? [])
        .filter((it) => msg === it)
        ?.length > 0

      if (!hasNotify) {
        var items = (allNotify ?? []).slice(0, 10);
        setAllNotify([...msg, items ?? []]);
        toast.success(msg);
      }
    }
  }

  const handleSendNotificationToUser = (userId, message, data) => {
    let msgNotify = data?.msgNotify ?? "";
    if ((msgNotify ?? "").length < 1) return;

    let staffIds = data?.staffIds ?? [];
    let hasUser = +userId === +myUserId;
    if (!hasUser) {
      hasUser = (staffIds ?? []).filter((it) => +it === +userId)?.length > 0;
    }

    hasUser && showMsg(msgNotify);
  }

  const handleSendNotificationToUsers = (userIds, message, data) => {
    let notify = data?.msgNotify ?? "";
    if ((notify ?? "").length < 1) return;

    let staffIds = data?.staffIds ?? [];
    let hasUser = (userIds ?? []).filter((it) => +it === +myUserId)?.length > 0;
    if (!hasUser) {
      hasUser = (staffIds ?? []).filter((it) => +it === +myUserId)?.length > 0;
    }

    hasUser && showMsg(notify);
  }

  const handleSendNotificationToTeam = (teamId, message, data) => {
    if (+teamId === +myTeamId) {
      let msgNotify = data?.msgNotify ?? "";
      if ((msgNotify ?? "").length < 1) return;
      if (msgNotify.length > 0) {
        showMsg(msgNotify)
      }
    }
  }

  return <>
    <header>
      <Menu hasAvatar />
      <div className="p-3 text-center bg-light">{children}</div>
    </header>
    <ToastContainer />
  </>
};

export default Layout;
