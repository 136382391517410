import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { first, orderBy, toNumber } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBCol, MDBIcon, MDBRow, MDBSwitch, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { cellStyleDone, cellStyleOverdue, dateFormatDMY, newGuid, nextMonth, onGridReady } from "../../utils/utilities";
import DisplayTaskProcessing from "./DisplayTaskProcessing";
import ViewMyTaskByCharts from "./ViewMyTaskByCharts";
import moment from "moment";
import DisplayOverdue from "../../components/DisplayOverdue";
import ApexPie from "../Dashboard/ApexPie";
import ColumnBarEstimate from "../Dashboard/ColumnBarEstimate";
import PhaseByDates from "../Dashboard/PhaseByDates";
import DisplayUserAndName from "../../components/DisplayUserAndName";

function MyTabContent({ userId, userRole, myTasks }) {
  const navigate = useNavigate();
  const [isUsed, setIsUsed] = useState(false);
  const [minRow, setMinRow] = useState(false);

  const renderByStatus = (items) => {
    let statusNames = (items ?? [])
      .map(item => item.statusName)
      .filter((value, index, self) =>
        self.indexOf(value) === index);

    return <>
      <MDBRow>
        {(statusNames ?? []).map((st) => {
          let stName = st?.length > 0 ? st : "N/A"
          return <MDBCol className=" truncate max-w-[75px]" title={st}>{stName}</MDBCol>
        })}
      </MDBRow>
      <MDBRow>
        {(statusNames ?? []).map((st) => {
          let data = (items ?? []).filter((it) => it.statusName === st);
          return <MDBCol title={`${st} (${data?.length} Tasks)`}><h4>{data?.length}</h4> </MDBCol>
        })}
      </MDBRow>
    </>
  }

  const calculateTaksIds = (items) => {
    let uniqueTaskIds = (items ?? []).filter((it) => (it.taskId ?? 0) > 0)
      .map(item => item.taskId)
      .filter((value, index, self) =>
        self.indexOf(value) === index);

    return uniqueTaskIds?.length ?? ""
  }

  const renderWorkingSummary = (items, data) => {
    let totalTasks = (items ?? []).length ?? 0;
    let totalDone = (items ?? []).filter((it) => it?.statusName === "Done" && it.staffId === userId)?.length ?? 0;
    let overdues = (data ?? []).filter((it) => it?.overdue && it.staffId === userId)?.length ?? 0;
    let workings = (data ?? []).filter((it) => !it?.overdue && it.staffId === userId)?.length ?? 0;
    let staffIds = [... new Set((data ?? []).filter((it) => it.staffId !== userId).map(x => x.staffId))];
    let members = (data ?? []).filter((it) => !it?.overdue && it.staffId !== userId)?.length ?? 0;
    let overs = (data ?? []).filter((it) => it?.overdue && it.staffId !== userId)?.length ?? 0;
    let otherDone = (items ?? []).filter((it) => it?.statusName === "Done" && it.staffId !== userId)?.length ?? 0;

    return <>
      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="8">
          My Workings
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill light title={"Workings"}>
            {`${workings} / ${totalTasks}`}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="8">
          Completed
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill color="success" title={"Tasks has been completed"}>
            {`${totalDone} / ${totalTasks}`}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="8">
          Overdues
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill color="warning" title={"Overdue tasks"}>
            {`${overdues} / ${totalTasks}`}
          </MDBBadge>
        </MDBCol>
      </MDBRow>

      <hr />
      <MDBRow style={{ textAlign: "left", marginTop: 18 }}>
        <MDBCol sm="8">
          Other Members
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill title={"Members"}>
            {staffIds?.length}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="8">
          Workings
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill light title={"Workings"}>
            {`${members} / ${totalTasks}`}
          </MDBBadge>
        </MDBCol>
      </MDBRow>

      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="8">
          Completed
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill color="success" title={"Tasks has been completed"}>
            {`${otherDone} / ${totalTasks}`}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="8">
          Overdues
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill color="warning" title={"Overdue tasks"}>
            {`${overs} / ${totalTasks}`}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
    </>
  }

  const renderColumnCharts = (names, estimateHours, dataNormals, dataOTs) => {
    if (names?.length < 1) return <></>

    if (names?.length < 6) {
      return <>
        <hr />
        <MDBCol sm="6">
          <ApexPie labels={names ?? []} series={estimateHours ?? []} />
        </MDBCol>
        <MDBCol sm="6">
          <ColumnBarEstimate categories={names}
            dataEstimates={estimateHours}
            dataNormals={dataNormals}
            dataOTs={dataOTs}
          />
        </MDBCol>
      </>
    }

    return <>
      <hr />
      <MDBCol sm="12">
        <ColumnBarEstimate categories={names}
          dataEstimates={estimateHours}
          dataNormals={dataNormals}
          dataOTs={dataOTs}
        />
      </MDBCol>
    </>
  }


  const [basicActive, setBasicActive] = useState('tabSummary');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  const renderMyTaskInfo = (items, minDate, maxDate, rangeByAllTasks) => {
    let unique = [...new Set((items ?? []).map((it) => it?.projectId))];

    if (unique?.length > 1) {
      let rs = [];
      let data = [];
      (unique).map((id) => {
        let pro = (items ?? []).filter((f) => f.projectId === id);
        let item = first(pro);
        let rangeStaffByTasks = (pro ?? [])
          .filter((it) => it?.staffName?.length > 0)
          .map((it) => {
            return {
              fromDate: dateFormatDMY(it?.startDate),
              name: `${it.staffId === userId ? "" : `[${it?.userName}]`} ${it?.taskName}`,
              toDate: dateFormatDMY(it?.endDate ?? nextMonth())
            }
          });

        if ((rangeStaffByTasks ?? []).length > 0) {
          rangeStaffByTasks.push({
            fromDate: dateFormatDMY(minDate),
            name: ``,
            toDate: dateFormatDMY(maxDate)
          });

          data.push({ itemList: rangeStaffByTasks ?? [], id: item.projectId });
          rs.push({ name: item.projectName, id: item.projectId })
        }
      });

      return <>
        <MDBTabs className='mb-3'>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick(`tabSummary`)} active={basicActive === `tabSummary`}>
              Summary
            </MDBTabsLink>
          </MDBTabsItem>
          {(rs ?? []).map((dt) => {
            return <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick(`${dt?.id}`)} active={basicActive === `${dt?.id}`}>
                {dt?.name}
              </MDBTabsLink>
            </MDBTabsItem>
          })}
        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane open={basicActive === `tabSummary`}>
            <PhaseByDates rangeDates={rangeByAllTasks ?? []} />
          </MDBTabsPane>
          {(data ?? []).map((dt) => {
            return <MDBTabsPane open={basicActive === `${dt?.id}`}>
              <PhaseByDates rangeDates={dt?.itemList ?? []} />
            </MDBTabsPane>
          })}
        </MDBTabsContent>
      </>
    }


    return <PhaseByDates rangeDates={items ?? []} />
  }

  const createSiteBar = () => {
    let ctrMin = minRow ? "" : <MDBIcon fas icon="minus-square"
      onClick={() => setMinRow(true)}
      title="Minimize view description"
      style={{ cursor: "pointer", marginRight: 25 }} />;

    let ctrExternal = !minRow ? "" : <MDBIcon
      fas
      icon="eye"
      title="External view description"
      onClick={() => setMinRow(false)}
      style={{ cursor: "pointer", marginRight: 25 }}
    />
    let lblTitle = isUsed ? "Has task only" : "Show all";

    return <MDBRow style={{ marginBottom: 10 }}>
      <MDBCol sm="3">
        <MDBSwitch
          id={`SW${newGuid()}`}
          label={lblTitle}
          checked={isUsed}
          onChange={(e) => setIsUsed(e.target.checked)}
        />
      </MDBCol>
      <MDBCol sm="6" style={{ textAlign: "right" }}>
        <MDBBadge color='success' style={{ marginRight: 20 }}>Normal working</MDBBadge>
        <MDBBadge color='warning' style={{ marginRight: 20 }}>Overtime</MDBBadge>
        <MDBBadge color='danger'>Underestimate</MDBBadge>
      </MDBCol>
      <MDBCol sm="3" className="displayEnd">
        {ctrMin} {ctrExternal}
      </MDBCol>
    </MDBRow>
  }

  const renderControls = (items) => {
    let myProject = (items ?? []).filter((it) => it.isPM);
    let myProjectTasks = (items ?? []).filter((it) => !it.isPM && it.staffId === userId);
    let otherTasks = (items ?? []).filter((it) => !it.isPM && it.staffId !== userId);

    let names = [];
    let estimateHours = [];
    let dataNormals = [];
    let dataOTs = [];

    (items ?? []).filter((it) => it.taskId > 0 &&
      it?.statusName !== "Done" &&
      (toNumber(it?.workingHours) + toNumber(it?.planHours) + toNumber(it?.otHours)) > 0)
      .map((item) => {
        names.push(`[${item?.taskId}] ${item?.taskName}`);
        estimateHours.push(item?.planHours ?? 0);
        dataNormals.push(item?.workingHours ?? 0);
        dataOTs.push(item?.otHours ?? 0);
      });

    let data = (items ?? [])
      .filter((it) => !isUsed || it.taskId > 0)
      .map((item) => {
        let st = item?.statusName !== "Done";
        var time1 = moment(item?.endDate ?? nextMonth()).format('YYYY-MM-DD');
        var time2 = moment(new Date()).format('YYYY-MM-DD');
        let overdue = time1 < time2;
        item.overdue = st && overdue;
        return item;
      });

    let titleDetails = "Details";
    let overdues = (data ?? []).filter((item) => item.overdue) ?? [];
    if (overdues?.length > 0) {
      titleDetails = `Details has ${overdues?.length} tasks Overdue`;
    }

    let minDate = new Date();
    let maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 7);

    let rangeByAllTasks = (data ?? [])
      .filter((it) => it?.staffName?.length > 0)
      .map((it) => {
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `${it.staffId === userId ? "" : `[${it?.userName}]`} ${it?.taskName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      });

    let rangeDates = (data ?? [])
      .filter((it) => it?.taskName?.length > 0)
      .map((it) => {
        if (it?.startDate < minDate) minDate = it?.startDate;
        if ((it?.endDate ?? nextMonth()) > maxDate) maxDate = it?.endDate ?? nextMonth();
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `[${it?.phaseName}] ${it?.taskName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      }) ?? [];

    rangeDates.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    })

    rangeByAllTasks.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    });

    let rangeStaffs = (data ?? [])
      .filter((it) => it?.staffName?.length > 0)
      .map((it) => {
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `${it?.staffName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      });

    rangeStaffs.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    });

    return <>
      <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle="Summary">
          <MDBRow>
            <MDBCol sm="4">
              <h3 className='mb-3'>My projects</h3>
            </MDBCol>
            <MDBCol sm="4">
              <h3 className='mb-3'>My tasks</h3>
            </MDBCol>
            <MDBCol sm="4">
              <h3 className='mb-3'>Others</h3>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="4">
              <h3 className='mb-3'>{calculateTaksIds(myProject)}</h3>
            </MDBCol>
            <MDBCol sm="4">
              <h3 className='mb-3'>{calculateTaksIds(myProjectTasks)}</h3>
            </MDBCol>
            <MDBCol sm="4">
              <h3 className='mb-3'>{calculateTaksIds(otherTasks)}</h3>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="4">
              {renderByStatus(myProject)}
            </MDBCol>
            <MDBCol sm="4">
              {renderByStatus(myProjectTasks)}
            </MDBCol>
            <MDBCol sm="4">
              {renderByStatus(otherTasks)}
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol sm="3">
              {renderWorkingSummary(items, data)}
            </MDBCol>
            <MDBCol sm="9">
              {renderMyTaskInfo(data, minDate, maxDate, rangeByAllTasks)}
            </MDBCol>
            {renderColumnCharts(names, estimateHours, dataNormals, dataOTs)}
          </MDBRow>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={titleDetails}>
          {createSiteBar()}
          <div className="ag-theme-quartz" style={{ height: 350, marginTop: 15 }}>
            <AgGridReact
              rowData={orderBy(data ?? [], "taskId")}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              onGridReady={onGridReady}
            />
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle="By Tasks">
          <ViewMyTaskByCharts items={items} userId={userId} isUsed={isUsed} />
        </MDBAccordionItem>
      </MDBAccordion>
    </>
  }

  const colDefs = [
    {
      field: "overdue",
      headerName: "",
      width: 95,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        return <DisplayOverdue
          statusName={row?.data?.statusName ?? ""}
          endDate={row?.data?.endDate}
        />
      }
    },
    {
      field: "statusName",
      headerName: "Status Name",
      filter: true,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
    },
    {
      field: "projectName",
      headerName: "Project",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
    },
    {
      field: "staffName",
      headerName: "Staff Name",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      cellRenderer: (row) => {
        return <DisplayUserAndName userName={row?.data?.userName} staffName={row?.data?.staffName} />
      },
      width: 205,
      pinned: 'left',
      lockPinned: true,
      filter: true
    },
    {
      field: "phaseName",
      headerName: "Phase",
      filter: true,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
    },
    {
      field: "taskName",
      headerName: "Task Name",
      width: 195,
      filter: true,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
    },
    {
      field: "taskDesc",
      headerName: "Task description",
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
      width: 215,
      filter: true,
      autoHeight: true
    },
    {
      field: "subTaskDesc",
      headerName: "SubTask description",
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
      width: 215,
      filter: true,
      autoHeight: true
    },
    {
      field: "startDate",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "taskId",
      headerName: "Processing",
      width: 265,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return <DisplayTaskProcessing
          statusName={row?.data?.statusName}
          planHours={row?.data?.planHours}
          workingHours={row?.data?.workingHours}
          otHours={row?.data?.otHours}
        />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "view",
      headerName: "",
      cellStyle: {
        textAlign: "left",
      },
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBCol sm="2">
            <MDBIcon fas icon="list-alt"
              style={{ cursor: "pointer" }}
              title={`View task ${row?.data?.taskName}`}
              onClick={() => handleViewTaskDetail(row?.data?.taskId, row?.data?.phaseId)}
            />
          </MDBCol>
        );
      },
    },
  ]

  const handleViewTaskDetail = (taskId, phaseId) => {
    if (+taskId < 1) return;

    let viewTaskDetail = {
      taskId: taskId,
      phaseId: phaseId
    };
    window.localStorage.setItem("viewTaskDetail", JSON.stringify(viewTaskDetail));
    navigate(`/tasks/${taskId ?? 0}`);
  }

  return renderControls(myTasks)
}

export default MyTabContent;
