import {
  MDBAccordionItem,
  MDBBadge,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { first } from "lodash";
import ColumnBarEstimate from "../pages/Dashboard/ColumnBarEstimate";
import DisplayTaskProcessing from "../pages/Planning/DisplayTaskProcessing";

function ViewSubtaskInfo({ subTasks }) {

  const renderTaskByUsers = (items) => {
    let unique = [...new Set(items.map((it) => it?.assignedId))];
    let itemTasks = (unique).map((id) => {
      let staffs = (items ?? []).filter((f) => f.assignedId === id);
      let staff = first(staffs);
      let estimateHours = 0;
      let normalHours = 0;
      let overTimeHours = 0;

      (staffs ?? []).map((it) => {
        estimateHours += it?.estimateHours ?? 0;
        normalHours += it?.normalHours ?? 0;
        overTimeHours += it?.overTimeHours ?? 0;
      })

      return <>
        <MDBRow>
          <MDBCol size="3" sm="6">{staff?.assigned}</MDBCol>
          <MDBCol size="3" sm="1">
            <MDBBadge pill light title={"Estimate Hours"}>
              {estimateHours ?? "N/A"}
            </MDBBadge>
          </MDBCol>
          <MDBCol size="3" sm="5">
            <DisplayTaskProcessing
              statusName={staff?.statusName}
              planHours={estimateHours}
              workingHours={normalHours ?? 0}
              otHours={overTimeHours ?? 0}
              displayOverHours
            />
          </MDBCol>
        </MDBRow>
      </>
    });


    return <MDBAccordionItem collapseId={1} headerTitle={"View by staffs"}>
      {itemTasks}
    </MDBAccordionItem>

  }

  const renderColumnCharts = (items) => {
    let names = [];
    let estimateHours = [];
    let dataNormals = [];
    let dataOTs = [];

    let unique = [...new Set(items.map((it) => it?.assignedId))];

    (unique).map((id) => {
      let staffs = (items ?? []).filter((f) => f.assignedId === id);
      let staff = first(staffs);
      let estHours = 0;
      let norHours = 0;
      let overHours = 0;

      (staffs ?? []).map((it) => {
        estHours += it?.estimateHours ?? 0;
        norHours += it?.normalHours ?? 0;
        overHours += it?.overTimeHours ?? 0;
      })

      names.push(staff?.assigned);
      estimateHours.push(estHours);
      dataNormals.push(norHours);
      dataOTs.push(overHours);
    });

    if (names?.length < 1) return <></>

    return <MDBAccordionItem collapseId={11} headerTitle={"View by charts"}>
      <ColumnBarEstimate categories={names}
        dataEstimates={estimateHours}
        dataNormals={dataNormals}
        dataOTs={dataOTs} />
    </MDBAccordionItem>
  }

  const renderBody = () => {
    if (subTasks?.length < 1) return <></>

    return <>
      {renderTaskByUsers(subTasks)}
      {renderColumnCharts(subTasks)}
    </>
  }

  return renderBody();

}

export default ViewSubtaskInfo;
