import { useEffect, useState } from "react";
import { isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { first } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { dateFormatDMY, nextMonth } from "../../utils/utilities";
import PhaseByDates from "../Dashboard/PhaseByDates";
import Multiselect from "multiselect-react-dropdown";

function ViewMyTaskByCharts({ items, userId, isUsed }) {
  const navigate = useNavigate();
  const [myTasks, setMyTasks] = useState(items ?? []);
  const [dataResults, setDataResults] = useState([]);
  const [searhResults, setSearhResults] = useState(items ?? []);

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([]);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  useEffect(() => {
    setMyTasks(items ?? []);
    setDataResults(items ?? []);
    setSearhResults(items ?? []);
  }, [items, isUsed]);


  const optionProjects = (items) => {
    let unique = [...new Set((items ?? []).map((it) => it?.projectId))];
    let rs = [];
    (unique).map((id) => {
      let pro = (items ?? []).filter((f) => f.projectId === id);
      let item = first(pro);
      rs.push({ name: item.projectName, id: item.projectId })
    });

    return rs;
  }

  const optionPhases = (dataResults) => {
    let unique = [...new Set((dataResults ?? []).map((it) => it?.phaseId))];
    let rs = [];
    (unique).map((id) => {
      let pro = (dataResults ?? []).filter((f) => f.phaseId === id);
      let item = first(pro);
      rs.push({ name: item.phaseName, id: item.phaseId })
    });

    return rs;
  }

  const optionTeams = () => {
    let unique = [...new Set((dataResults ?? [])
      .filter((f) => +f.teamId > 0)
      .map((it) => it?.teamId))];
    let rs = [];
    (unique).map((id) => {
      let pro = (dataResults ?? []).filter((f) => f.teamId === id);
      let item = first(pro);
      rs.push({ name: item.teamName, id: item.teamId })
    });

    return rs;
  }

  const optionStaffs = (dataResults) => {
    let unique = [...new Set((dataResults ?? [])
      .filter((f) => +f.staffId > 0)
      .map((it) => it?.staffId))];
    let rs = [];
    (unique).map((id) => {
      let pro = (dataResults ?? []).filter((f) => f.staffId === id);
      let item = first(pro);
      rs.push({ name: item.staffName, id: item.staffId })
    });

    return rs;
  }

  const onSelectedProjects = (opts) => {
    let ids = (opts ?? []).map((it) => it.id);
    let rs = (items ?? []).filter((p) => ids.some((id) => id === p.projectId));
    setDataResults(rs ?? []);
    setSelectedProjects(opts ?? []);
  }

  const onSelectedPhases = (opts) => {
    let ids = (opts ?? []).map((it) => it.id);
    let rs = (dataResults ?? []).filter((p) => ids.some((id) => id === p.phaseId));
    setSearhResults(rs ?? []);
    setSelectedPhases(opts ?? []);
  }

  const onSelectedTeams = (opts) => {
    let ids = (opts ?? []).map((it) => it.id);
    let rs = (dataResults ?? []).filter((p) => ids.some((id) => id === p.teamId));
    setSearhResults(rs ?? []);
    setSelectedTeams(opts ?? []);
  }

  const onSelectedStaffs = (opts) => {
    let ids = (opts ?? []).map((it) => it.id);
    let rs = (dataResults ?? []).filter((p) => ids.some((id) => id === p.staffId));
    setSearhResults(rs ?? []);
    setSelectedStaffs(opts ?? []);
  }

  const loadAdllProjects = () => {
    let staffIds = (selectedStaffs ?? []).map((it) => it.id);
    let phaseIds = (selectedPhases ?? []).map((it) => it.id);
    let teamIds = (selectedTeams ?? []).map((it) => it.id);

    let rs = (dataResults ?? [])
      .filter((p) => phaseIds?.length < 1 || phaseIds.some((id) => id === p.phaseId))
      .filter((p) => teamIds?.length < 1 || teamIds.some((id) => id === p.teamId))
      .filter((p) => staffIds?.length < 1 || staffIds.some((id) => id === p.staffId));

    setSearhResults(rs ?? []);
  }

  const renderControlSearchData = () => {
    return <>
      <MDBRow style={{ marginBottom: 15 }}>
        <Multiselect
          options={optionProjects(myTasks)}
          onSelect={(opts) => onSelectedProjects(opts)}
          onRemove={(opts) => onSelectedProjects(opts)}
          hidePlaceholder={(selectedProjects ?? []).length > 0}
          displayValue="name"
          placeholder="Select project(s)"
        />
      </MDBRow>
      <MDBRow style={{ marginBottom: 15 }}>
        <Multiselect
          options={optionPhases(dataResults ?? [])}
          onSelect={(opts) => onSelectedPhases(opts)}
          onRemove={(opts) => onSelectedPhases(opts)}
          displayValue="name"
          hidePlaceholder={(selectedPhases ?? []).length > 0}
          placeholder="Select phases"
        />
      </MDBRow>
      <MDBRow style={{ marginBottom: 15 }}>
        <Multiselect
          options={optionTeams(dataResults)}
          onSelect={(opts) => onSelectedTeams(opts)}
          onRemove={(opts) => onSelectedTeams(opts)}
          displayValue="name"
          hidePlaceholder={(selectedTeams ?? []).length > 0}
          placeholder="Select Teams"
        />
      </MDBRow>
      <MDBRow style={{ marginBottom: 15 }}>
        <Multiselect
          options={optionStaffs(dataResults)}
          onSelect={(opts) => onSelectedStaffs(opts)}
          onRemove={(opts) => onSelectedStaffs(opts)}
          displayValue="name"
          hidePlaceholder={(selectedStaffs ?? []).length > 0}
          placeholder="Select Staffs"
        />
      </MDBRow>
      <MDBRow style={{ marginBottom: 15 }}>
        <MDBCol size="3" sm="4"></MDBCol>
        <MDBCol size="3" sm="8">
          <MDBBtn color="info"
            className="mb-3"
            style={{ width: 150, height: 46, cursor: "pointer" }}
            onClick={() => loadAdllProjects()} >
            <MDBIcon fas icon="search" style={{ marginRight: 16 }} /> Search
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </>
  }

  const renderControls = () => {
    let data = (searhResults ?? []).filter((it) => !isUsed || it.taskId > 0);
    let minDate = new Date();
    let maxDate = new Date();
    maxDate.setDate(minDate.getDate() + 7);

    let rangeDates = (data ?? [])
      .filter((it) => it?.taskName?.length > 0)
      .map((it) => {
        if (it?.startDate < minDate) minDate = it?.startDate;
        if ((it?.endDate ?? nextMonth()) > maxDate) maxDate = it?.endDate ?? nextMonth();
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `[${it?.phaseName}] ${it?.taskName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      }) ?? [];

    rangeDates.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    })

    let rangeStaffs = (data ?? [])
      .filter((it) => it?.staffName?.length > 0)
      .map((it) => {
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `${it?.staffName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      });

    rangeStaffs.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    });

    let rangeStaffByTasks = (data ?? [])
      .filter((it) => it?.staffName?.length > 0)
      .map((it) => {
        return {
          fromDate: dateFormatDMY(it?.startDate),
          name: `${it.staffId === userId ? "" : `[${it?.staffName}]`} ${it?.taskName}`,
          toDate: dateFormatDMY(it?.endDate ?? nextMonth())
        }
      });

    rangeStaffByTasks.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    })

    return <>
      <MDBRow style={{ marginBottom: 20 }}>
        <MDBCol size="3" sm="3">
          {renderControlSearchData()}
        </MDBCol>
        <MDBCol size="3" sm="9">
          <MDBAccordion alwaysOpen initialActive={1}>
            <MDBAccordionItem collapseId={1} headerTitle="By Tasks">
              <PhaseByDates rangeDates={rangeDates ?? []} />
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={2} headerTitle="By Staffs">
              <PhaseByDates rangeDates={rangeStaffs ?? []} />
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Staff By Tasks">
              <PhaseByDates rangeDates={rangeStaffByTasks ?? []} />
            </MDBAccordionItem>
          </MDBAccordion>
        </MDBCol>
      </MDBRow>
    </>
  }

  return (
    <>
      {renderControls()}
    </>
  );
}

export default ViewMyTaskByCharts;
