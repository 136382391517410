import { AgGridReact } from "ag-grid-react";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import axios from "axios";
import { orderBy } from "lodash";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";
import DisplayUserAndName from "../../components/DisplayUserAndName";

function ViewTimeSheets() {
  const navigate = useNavigate();
  const [projectTasks, setProjectTasks] = useState();
  const [minRow, setMinRow] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Time Sheets";
  }, []);

  useEffect(() => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Timesheets`,
        configHeader()
      )
      .then((result) => {
        setProjectTasks(result?.data);
      });
  }, []);

  const colDefs = [
    // {
    //   headerName: "Row",
    //   valueGetter: "node.rowIndex + 1",
    //   width: 65,
    // },
    {
      field: "projectName",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "displayName",
      headerName: "Staff",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        return <DisplayUserAndName userName={row?.data?.userName} staffName={row?.data?.displayName} />
      },
    },
    {
      field: "teamName",
      headerName: "Team",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actionDate",
      width: 125,
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "normalHours",
      width: 65,
      headerName: "Working",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "overTimeHours",
      width: 65,
      headerName: "OT",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "note",
      headerName: "Time sheet note",
      filter: true,
      autoHeight: true,
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      width: 85,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "taskName",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "phaseName",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  const renderCtrl = () => {
    let ctrMin = minRow ? "" : <MDBIcon fas icon="minus-square"
      onClick={() => setMinRow(true)}
      title="Minimize view description"
      style={{ cursor: "pointer", marginRight: 25 }} />;

    let ctrExternal = !minRow ? "" : <MDBIcon
      fas
      icon="eye"
      title="External view description"
      onClick={() => setMinRow(false)}
      style={{ cursor: "pointer", marginRight: 25 }}
    />

    return <MDBRow style={{ marginBottom: 15 }}>
      <MDBCol sm="9" />
      <MDBCol sm="3" className="displayEnd">
        {ctrMin} {ctrExternal}
      </MDBCol>
    </MDBRow>
  }

  return (
    <>
      {renderCtrl()}
      <div className="ag-theme-quartz" style={{ height: "75vh" }}>
        <AgGridReact
          rowData={orderBy(projectTasks, "priorityNumber")}
          columnDefs={colDefs}
          onGridReady={onGridReady}
        />
      </div>
    </>

  );
}

export default ViewTimeSheets;
