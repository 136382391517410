import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { first, toNumber } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { newGuid } from "../utils/utilities";

// const statusOfSubTask = [
//   { id: 1, statusName: "To do" },
//   { id: 2, statusName: "Working" },
//   { id: 3, statusName: "Waiting approval" },
//   { id: 4, statusName: "Done" },
// ];

function CreateNewSubTask({
  taskId,
  teamId,
  taskName,
  staffs,
  stsName,
  projectResources,
  isPM,
  onCloseModal,
  onSave,
}) {

  const navigate = useNavigate();
  const [subTaskName, setSubTaskName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState("");
  const [statusName, setStatusName] = useState(stsName ?? "To do");
  const [estimateHours, setEstimateHours] = useState();
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [uId, setUId] = useState();
  const [staffItems, setStaffItems] = useState([]);
  const [userTeamId, setUserTeamId] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUId(toNumber(infoObj?.id ?? 0));
    setUserName(infoObj?.userName ?? "");
    setUserTeamId(infoObj.teamId);
    setStaffItems(staffs?.staffs ?? [])
  }, []);

  useEffect(() => {
    if (stsName?.length > 0) setStatusName(stsName);
    else setStatusName("To do");
  }, [stsName]);

  useEffect(() => {
    let items = staffs?.staffs ?? [];
    setStaffItems(items)
    if (items?.length === 1) {
      let pro = first(items ?? []) ?? {};
      setUserId(pro?.id);
    } else {
      setUserId(uId)
    }
  }, [staffs]);

  useEffect(() => {
    let items = staffs?.staffs ?? [];

    if (items?.length < 1) {
      setStaffItems(projectResources?.staffs ?? [])
    } else {
      setStaffItems(items);
    }
  }, [staffs, projectResources])

  // const handleCurrent = (name, checked) => {
  //   if (checked) {
  //     setStatusName(name);
  //   }
  // };

  const validateDataBeforeSave = () => {
    if (userId < 1) {
      toast.warning("Please select a staff for this assignment");
      return false;
    }

    if (taskId < 1) {
      toast.warning("Please select task again.");
      return false;
    }

    if ((subTaskName ?? "").length < 1) {
      toast.warning("Name is required, please input this field.");
      return false;
    }

    return true;
  };

  const saveData = () => {
    let valid = validateDataBeforeSave();
    if (valid) {
      onSave({
        name: subTaskName,
        estimateHours: estimateHours,
        startDate: startDate,
        endDate: endDate,
        priorityNumber: priorityNumber,
        taskId: taskId,
        teamId: teamId,
        assignedId: userId,
        statusName: statusName,
        description: description,
      });
      onCloseModal();
    }
  };

  const options = () => {
    let optStaffs = staffs?.staffs ?? [];
    let userNamePM = staffs?.userNamePM ?? "";

    if (optStaffs.length < 1) {
      optStaffs = projectResources?.staffs ?? [];
      userNamePM = projectResources?.userNamePM ?? "";
    }

    if (!isPM) {
      isPM = (userName?.length > 0) && userName === userNamePM;
    }

    if (!isPM && teamId > 0) {
      return (optStaffs ?? []).filter((s) => s.teamId === +teamId)
        .map((p) => {
          return { name: `${p.userName} (${p.displayName})`, id: p.id };
        }) ?? [];
    } else {
      return (optStaffs ?? []).filter((s) => isPM || s.teamId === +userTeamId).map((p) => {
        return { name: `${p.userName} (${p.displayName})`, id: p.id };
      }) ?? [];
    }
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id) ?? [];
    let rs = (staffItems ?? []).filter((p) => ids.some((id) => id === p.id)) ?? [];
    let pro = first(rs) ?? {};
    setUserId(pro?.id);
  };

  // const renderStatus = (item) => {
  //   return (
  //     <MDBCol size="3" sm="4">
  //       <MDBRow>
  //         <MDBCol size="6" sm="3">
  //           <MDBSwitch
  //             id={newGuid()}
  //             checked={statusName === item?.statusName}
  //             onChange={(e) => handleCurrent(item?.statusName, e.target.checked)}
  //           />
  //         </MDBCol>
  //         <MDBCol size="6" sm="9" className=" truncate max-w-[150px]">
  //           {item?.statusName}
  //         </MDBCol>
  //       </MDBRow>
  //     </MDBCol>
  //   );
  // };

  const renderBody = (opts) => {
    if (opts?.length < 1) return <>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="12">
          <label>
            Don't have any available resources for this task.
          </label>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="12">
          PM couldn't assign resources to the tasks in Project.
        </MDBCol>
      </MDBRow>
    </>

    //if (opts?.length === 1) return <></>
    let hasUser = (opts ?? []).filter((op) => op.id !== +uId)?.length > 0;
    let divUsers = "";
    if (hasUser) {
      divUsers = <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="12">
          <Multiselect
            options={opts}
            singleSelect
            placeholder="Select a staff"
            label="Assign to Staff"
            onSelect={(items) => onSelectedValues(items)}
            onRemove={(items) => onSelectedValues(items)}
            displayValue="name"
          />
        </MDBCol>
      </MDBRow>
    }

    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Subtask Name"
          title="Name of sub task"
          value={subTaskName}
          onChange={(evt) => setSubTaskName(evt.target.value)}
        />
        {/* <MDBRow
          style={{ marginTop: 15 }}
          title="Select a status for this sub task"
        >
          {(statusOfSubTask ?? []).map((item) => renderStatus(item))}
        </MDBRow> */}
        {divUsers}
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="3" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              label="Estimate"
              title="Estimate Hours"
              value={estimateHours}
              style={{ padding: 5 }}
              onChange={(evt) => setEstimateHours(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="3" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              label="Priority"
              value={priorityNumber}
              style={{ padding: 5 }}
              onChange={(evt) => setPriorityNumber(evt.target.value)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" style={{ textAlign: "left" }}>
            <label className="  text-sm dark:text-white text-gray-500">
              Plan completed date
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="3" sm="6">
            <DatePicker
              selected={startDate}
              className="css-from-date-project"
              onChange={(date) => setStartDate(date)}
            />
          </MDBCol>
          <MDBCol size="3" sm="6">
            <DatePicker
              selected={endDate}
              className="css-from-date-project"
              onChange={(date) => setEndDate(date)}
            />
          </MDBCol>
        </MDBRow>

        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaDescription"
          rows="{4}"
          value={description ?? ""}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  const renderCtrMainBody = () => {
    let optStaffs = options();
    let saveBtn = optStaffs?.length < 1 ? "" : <MDBBtn onClick={() => saveData()}>Create a New Subtask</MDBBtn>
    return <>
      <MDBModalBody>{renderBody(optStaffs ?? [])}</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={onCloseModal}>
          Close
        </MDBBtn>
        {saveBtn}
      </MDBModalFooter>
    </>
  }

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>New Sub Task of {taskName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          {renderCtrMainBody()}
        </MDBModalContent>
      </MDBModalDialog>
      <ToastContainer />
    </MDBModal>
  );
}

export default CreateNewSubTask;
