import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import "./modal.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiEndpoint } from "../utils/apiEndpoint";
import { configHeader } from "../utils/tokenHelper";
import { dateFormatDMY, userRoles } from "../utils/utilities";
import DatePicker from "react-datepicker";

function PhasePublish({ projectId, phaseInfo, userRole, onClose }) {
  const [phasePublishing, setPhasePublishing] = useState([]);
  const [publishDate, setPublishDate] = useState(new Date());
  const [publishName, setPublishName] = useState('');

  useEffect(() => {
    reloadPublishing(phaseInfo?.id)
  }, [phaseInfo]);

  const reloadPublishing = (phaseId) => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/Publishing`,
        configHeader()
      )
      .then((result) => {
        setPhasePublishing(result?.data ?? []);
      });
  };

  const saveData = () => {
    let data = {
      phaseId: phaseInfo?.id,
      projectId: projectId,
      name: publishName,
      publishDate: publishDate
    };

    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseInfo?.id}/Publishing`,
        data,
        configHeader()
      )
      .then(() => {
        reloadPublishing(phaseInfo?.id)
      });
  }

  const handleDeletePublish = (id) => {
    axios
    .delete(
      `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/Publishings/${id}`,
      configHeader()
    )
    .then(() => {
      reloadPublishing(phaseInfo?.id)
    });
  }

  const renderHeaderPage = () => {
    if (phaseInfo?.estimateHours > 0)
      return (
        <MDBModalTitle>
          Add Publish to {phaseInfo?.name} ({phaseInfo?.estimateHours})
        </MDBModalTitle>
      );

    return <MDBModalTitle>Add Publish to {phaseInfo?.name}</MDBModalTitle>;
  };

  const renderNewPublishing = (hasPermission) => {
    if (!hasPermission) return <></>
    return <MDBRow style={{ textAlign: "left", marginBottom: 15 }}>
      <MDBCol sm="6" >
        <MDBInput
          className="mb-3"
          type="text"
          label="Publish Name"
          value={publishName}
          style={{ padding: 5 }}
          onChange={(evt) => setPublishName(evt.target.value)}
        />
      </MDBCol>
      <MDBCol sm="4" >
        <DatePicker
          selected={publishDate}
          onChange={(date) => setPublishDate(date)}
        />
      </MDBCol>
      <MDBCol sm="2">
        <MDBBtn disabled={(publishName ?? "").length < 1} onClick={() => saveData()}>Publish</MDBBtn>
      </MDBCol>
    </MDBRow>
  }

  const renderBody = () => {
    let hasPermission = userRole === userRoles.Admin ?? phasePublishing[0]?.isPM ?? false;
    if ((phasePublishing ?? []).length < 1) return <>
      {renderNewPublishing(hasPermission)}
      <MDBRow>
        <MDBCol sm="1"></MDBCol>
        <MDBCol sm="10">Not yet assign any plan publish</MDBCol>
      </MDBRow>
    </>

    return <>
      {renderNewPublishing(hasPermission)}
      {(phasePublishing ?? []).map((item) => {
        return <MDBRow>
          <MDBCol sm="6" style={{ textAlign: "left" }}>
            {item?.name}
          </MDBCol>
          <MDBCol sm="4">{dateFormatDMY(item?.publishDate)}</MDBCol>
          <MDBCol sm="2">
            <MDBIcon
              fas
              icon="trash-alt" color="warning"
              style={{ cursor: "pointer", marginRight: 10 }}
              title={`Delete Publish ${item?.name}`}
              onClick={() => handleDeletePublish(item?.id)}
            />
          </MDBCol>
        </MDBRow>
      })}
    </>
  }

  return <MDBModal
    open={true}
    onClose={onClose}
    tabIndex="-1"
    className="projectDialog"
  >
    <MDBModalDialog>
      <MDBModalContent>
        <MDBModalHeader>
          {renderHeaderPage()}
          <MDBBtn
            className="btn-close"
            color="none"
            onClick={onClose}
          ></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="divScroll50Vh">
            {renderBody()}
          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={onClose}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModalContent>
    </MDBModalDialog>
  </MDBModal>
}

export default PhasePublish;
