import React from "react";
import Chart from "react-apexcharts";

function ColumnBarEstimate({ categories, dataEstimates, dataNormals, dataOTs }) {
  let opts = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories ?? [],
    },
    yaxis: {
      title: {
        text: " (hours)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return " " + val + " hours";
        },
      },
    },
  };

  let series = [
    {
      name: "Estimate",
      data: dataEstimates,
    },
    {
      name: "Actual time",
      data: dataNormals,
    },
    {
      name: "OT time",
      data: dataOTs,
    },
  ];

  return <Chart
    options={opts}
    series={series}
    type="bar"
    height={350}
  />
}

export default ColumnBarEstimate;
