import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow, MDBIcon} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import PlanCompletedDate from "../../components/PlanCompletedDate";
import ApexPie from "../Dashboard/ApexPie";
import PhaseByDates from "../Dashboard/PhaseByDates";
import { dateFormatDMY, nextMonth } from "../../utils/utilities";
import ViewPhaseMembers from "./ViewPhaseMembers";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";

function ViewProjectMembers({ projectInfo, userRole, onCloseModal }) {

  const navigate = useNavigate();
  const [projectName, setProjectName] = useState(projectInfo?.name ?? "");
  const [projectId, setProjectId] = useState(projectInfo?.id ?? 0);
  const [projectMembers, setProjectMembers] = useState([]);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  useEffect(() => {
    setProjectName(projectInfo?.name ?? "");
    setProjectId(projectInfo?.id ?? 0)
    projectInfo?.id && reloadMembers(projectInfo?.id ?? 0)
  }, [projectInfo]);

  useEffect(() => {
    if ((projectId ?? 0) > 0) {
      reloadMembers(projectId);
    }
  }, [projectId]);

  const reloadMembers = (projectId) => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${projectId}/Members`,
        configHeader()
      )
      .then((result) => {
        setProjectMembers(result?.data ?? []);
      });
  };

  const renderCharts = () => {
    let phaseNames = [];
    let phaseHours = [];
    let rangeDates = [];
    let minDate = new Date();
    let maxDate = new Date();
    let totalEst = 0;
    maxDate.setDate(minDate.getDate() + 7);

    (projectInfo?.phaseInfo ?? []).map((p) => {
      if ((p?.name ?? "").length > 0) {
        phaseNames.push(p?.name ?? "");
        phaseHours.push(p?.estimateHours ?? 0);
        totalEst += (p?.estimateHours ?? 0)
        rangeDates.push({
          fromDate: dateFormatDMY(p?.startDate),
          name: `[${p?.id}] ${p?.name}`,
          toDate: dateFormatDMY(p?.endDate ?? nextMonth())
        });
        if (p?.startDate < minDate) minDate = p?.startDate;
        if ((p?.endDate ?? nextMonth()) > maxDate) maxDate = p?.endDate ?? nextMonth();
      }
    });

    rangeDates.push({
      fromDate: dateFormatDMY(minDate),
      name: ``,
      toDate: dateFormatDMY(maxDate)
    })

    return <MDBAccordionItem collapseId={4} headerTitle="Timeline">
      <PhaseByDates rangeDates={rangeDates ?? []} />
      {totalEst > 0 && <ApexPie labels={phaseNames} series={phaseHours} />}
    </MDBAccordionItem>
  }

  const renderBody = () => {
    return (
      <>
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="Project information">
            <MDBRow>
              <MDBCol
                size="3"
                sm="9"
                className=" truncate max-w-[250px]"
                title={`PM is ${projectInfo?.assigned}`}
              >
                <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
                {projectInfo?.assigned}
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ textAlign: "left", marginTop: 15 }}>
              <MDBCol sm="3" >
                {projectInfo?.statusName}
              </MDBCol>
              <MDBCol size="3" sm="6">
                <PlanCompletedDate
                  startDate={projectInfo?.startDate}
                  endDate={projectInfo?.endDate}
                  formatDMY="DD-MMM-YYYY"
                />
              </MDBCol>
            </MDBRow>
          </MDBAccordionItem>
          {renderCharts()}

          <MDBAccordionItem collapseId={3} headerTitle="Phases information">
            {renderMembers((projectMembers ?? []).filter((it) => it.name?.length > 0))}
          </MDBAccordionItem>
        </MDBAccordion>
      </>
    );
  };

  const renderMembers = (items) => {
    return <MDBAccordion alwaysOpen initialActive={1}>
      {(items ?? []).map((item, idx) => {
        return <MDBAccordionItem collapseId={item?.id} headerTitle={<>{`${item?.name} - ${item?.statusName}`}</>} key={`ctrl-${item?.id}-${idx}`}>          
          <ViewPhaseMembers projectId={projectId} phaseInfo={item} userRole={userRole}/>
        </MDBAccordionItem>
      })}
    </MDBAccordion>
  }

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Members of Project {projectName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="divScroll">{renderBody()}</div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ViewProjectMembers;
