import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import "react-toastify/dist/ReactToastify.css";

function MyBoardTasks() {

  const renderHighlights = () => {
    return <>
    </>
  }

  const renderGrids = () => {
    return <>
    </>
  }

  const renderSearchControl = () => {
    return <>
    </>
  }
 
  return <>
    {renderSearchControl()}
    <MDBAccordion alwaysOpen initialActive={1}>
      {renderHighlights()}
      <MDBAccordionItem collapseId={1}>
        {renderGrids()}
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2}>
        {renderHighlights()}
      </MDBAccordionItem>
    </MDBAccordion>

  </>
}

export default MyBoardTasks;
