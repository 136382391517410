import {
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "./modal.css";
import Multiselect from "multiselect-react-dropdown";
import { first } from "lodash";
import { useState } from "react";
import moment from "moment";
import { newGuid } from "../utils/utilities";

function ChangeMemberSubTask({ staffs, teamId, subTaskInfo, subTaskName, onSave, onCloseModal }) {
  const [userId, setUserId] = useState();

  const options = () => {
    let isPM = subTaskInfo?.isPM ?? false;

    if (!isPM) {
      return (staffs ?? []).filter((p) => p.teamId === +teamId)
        .map((p) => {
          return { name: `${p.userName} (${p.displayName})`, id: p.id };
        });
    }

    return (staffs ?? []).map((p) => {
      return { name: `${p.userName} (${p.displayName})`, id: p.id };
    });
  }

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (staffs ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setUserId(pro?.id);
  };

  const renderWorkingTime = () => {
    return (
      <MDBRow >
        <MDBCol size="3" sm="4">
          <MDBBadge pill light title={"Estimate Hours"}>
            {subTaskInfo?.estimateHours ?? "N/A"}
          </MDBBadge>
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBBadge pill color="info" title={"Normal Hours"}>
            {subTaskInfo?.normalHours ?? 0}
          </MDBBadge>
        </MDBCol>
        <MDBCol size="3" sm="4">
          <MDBBadge pill color="warning" title={"OT Hours"}>
            {subTaskInfo?.overTime ?? 0}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
    );
  };

  const renderPlanCompletedDate = () => {
    let fromDate = !subTaskInfo?.startDate ? "TBD" : moment(subTaskInfo?.startDate).format("DD-MMM");
    let toDate = !subTaskInfo?.endDate ? "TBD" : moment(subTaskInfo?.endDate).format("DD-MMM");

    return (
      <>
        <MDBRow
          style={{ marginTop: 5 }}
          title={`From ${fromDate} => ${toDate}`}
        >
          <MDBCol size="3" sm="2">
            <MDBIcon fas icon="calendar-alt" />
          </MDBCol>
          <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
            <p className="text-primary">{fromDate}</p>
          </MDBCol>
          <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
            <p className="text-warning">{toDate}</p>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  const renderDescription = () => {

    if ((subTaskInfo?.description ?? "").length < 1) return "";

    return <MDBTextArea
      className="mb-3"
      id={`txt${newGuid()}`}
      rows="{4}"
      style={{ marginTop: 15 }}
      value={subTaskInfo?.description ?? ""}
      readOnly
      disabled
    />
  }

  const saveData = () => {
    onSave(subTaskInfo?.id, userId);
  }

  const renderBody = () => {
    return (
      <>
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol
            size="3"
            sm="12"
            className=" truncate max-w-[250px]"
            title={subTaskInfo?.assigned}
          >
            <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
            {subTaskInfo?.assigned}
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="3" sm="6">
            {renderPlanCompletedDate()}
          </MDBCol>
          <MDBCol size="3" sm="6">
            {renderWorkingTime()}
          </MDBCol>
        </MDBRow>

        <MDBRow style={{ textAlign: "left", marginTop: 15 }}>
          <MDBCol sm="3">Change to</MDBCol>
          <MDBCol sm="6">
            <Multiselect
              options={options()}
              singleSelect
              onSelect={(items) => onSelectedValues(items)}
              onRemove={(items) => onSelectedValues(items)}
              displayValue="name"
              placeholder="Select a Staff"
            />
          </MDBCol>
          <MDBCol sm="3">
            <MDBBtn onClick={() => saveData()}>Save</MDBBtn>
          </MDBCol>
        </MDBRow>

        {renderDescription()}
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className=" truncate max-w-[300px]">
              {subTaskName}
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>

          <MDBModalBody>
            <div className="divScroll">{renderBody()}</div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ChangeMemberSubTask;
