import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./Bill.css";
import { orderBy, toNumber, uniq } from "lodash";
import { AgGridReact } from "ag-grid-react";
import CreateNewContract from "./CreateNewContract";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { toast, ToastContainer } from "react-toastify";
import ViewAmount from "./ViewAmount";
import InputContractAmount from "./InputContractAmount";

function Contracts() {
  const navigate = useNavigate();
  const [bills, setBills] = useState([]);
  const [planReceivings, setPlanReceivings] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [planAmount, setPlanAmount] = useState([]);
  const [clientReceivings, setClientReceivings] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "List of Contracts";
  }, []);

  useEffect(() => {
    reloadBills();
  }, []);

  const reloadBills = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/PlanReceivings`,
        configHeader()
      )
      .then((result) => {
        setBills(result?.data ?? []);
        renderProjects(result?.data ?? []);
        renderByClients(result?.data ?? []);
      });
  };

  const colDefClients = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      cellStyle: row => {
        if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
          return { backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
        }
        return {
          textAlign: "left",
        };
      },
      width: 275,
      filter: true
    },
    {
      field: "receiptDate",
      headerName: "Date",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "totalAmout",
      headerName: "Amount",
      filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      width: 175,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.totalAmout} />
    },
    {
      field: "advancePayment",
      headerName: "Advance Payment",
      filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      width: 175,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.advancePayment} />
    },
  ]

  const colDefReceivings = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "receiptNo",
      headerName: "Receipt #",
      cellStyle: row => {
        if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
          return { textAlign: "left", backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
        }
        return {
          textAlign: "left",
        };
      },
      filter: true
    },
    {
      field: "companyName",
      headerName: "Company Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: row => {
        if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
          return { backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
        }
        return {
          textAlign: "left",
        };
      },
      width: 175,
      filter: true
    },
    {
      field: "projectName",
      headerName: "Project Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
      filter: true
    },
    {
      field: "receiptDate",
      headerName: "Date",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "totalAmout",
      headerName: "Amount",
      pinned: 'left',
      lockPinned: true,
      filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      width: 175,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.totalAmout} />
    },
    {
      field: "advancePayment",
      headerName: "Advance Payment",
      filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      width: 175,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.advancePayment} />
    },
  ]

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    // {
    //   field: "receiptNo",
    //   headerName: "Receipt #",
    //   cellStyle: row => {
    //     if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
    //       return { backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
    //     }
    //     return {
    //       textAlign: "left",
    //     };
    //   },
    //   filter: true
    // },
    {
      field: "companyName",
      headerName: "Company Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: row => {
        if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
          return { backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
        }
        return {
          textAlign: "left",
        };
      },
      width: 175,
      filter: true
    },
    {
      field: "projectName",
      headerName: "Project Name",
      cellStyle: row => {
        if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
          return { backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
        }
        return {
          textAlign: "left",
        };
      },
      width: 135,
      filter: true
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      cellStyle: row => {
        if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
          return { backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
        }
        return {
          textAlign: "left",
        };
      },
      width: 135,
      filter: true
    },
    {
      field: "planReceivingDate",
      pinned: 'left',
      lockPinned: true,
      headerName: "Date",
      cellStyle: row => {
        if (toNumber(row.data?.totalAmout) <= toNumber(row.data?.advancePayment)) {
          return { backgroundColor: 'gray', fontWeight: "bold", textDecorationLine: "line-through" };
        }
        return {
          textAlign: "left",
        };
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "totalAmout",
      headerName: "Amount",
      filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.totalAmout} />
    },
    {
      field: "advancePayment",
      headerName: "Advance Payment",
      filter: "agNumberColumnFilter",
      cellStyle: {
        textAlign: "right",
      },
      width: 165,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.advancePayment} />
    },
    {
      field: "receiptDate",
      headerName: "Payment Date",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        if (toNumber(row.data?.advancePayment) > 0) return dateFormatDMY(row.data?.receiptDate);
        return ""
      },
      width: 135,
    },
    {
      field: "description",
      headerName: "Description",
      cellStyle: {
        textAlign: "left",
      },
      width: 175,
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="file-invoice-dollar"
            onClick={() => handleOpenPopup(row.data)}
          />
        );
      },
    }
  ];

  const handleOpenPopup = (data) => {
    setPlanAmount(data);
    setOpen(true);
  }

  const handleSaveReceipt = (data) => {
    if (data?.phaseId < 1) {
      toast.error(
        "Your phase is empty, please select a phase for receipt."
      );
      return;
    }

    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/Short-Receipt`, data,
        configHeader()
      )
      .then(() => {
        reloadBills();
        setOpen(false);
        toast.success("Your Receipt has been created.");
      });
  }

  const handleSaveData = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/PlanReceiving`, data,
        configHeader()
      )
      .then(() => {
        reloadBills();
        setOpenCreateModal(false);
        toast.success("Your Receipt has been created.");
      });
  }

  const renderByClients = (bills) => {
    let companyIds = (bills ?? []).map((it) => it.companyId);
    let items = uniq(companyIds);

    let divs = (items ?? []).map((item) => {
      let totalAmouts = 0;
      let advancePayments = 0;
      let companyName = "";
      let receiptNo = "";
      let receiptDate;

      (bills ?? []).filter((it) => it.companyId === item)
        .map((it) => {
          totalAmouts += toNumber(it.totalAmout);
          advancePayments += toNumber(it.advancePayment);
          companyName = it.companyName ?? "";
          //receiptNo = it.receiptNo ?? "";
          receiptDate = it.receiptDate
        });

      return {
        //projectName: item,
        //receiptNo: receiptNo,
        companyName: companyName,
        totalAmout: totalAmouts,
        advancePayment: advancePayments,
        receiptDate: receiptDate
      }
    })

    setClientReceivings(divs);
  }

  const renderProjects = (bills) => {
    let projects = (bills ?? []).map((it) => it.projectName);
    let items = uniq(projects);

    let divs = (items ?? []).map((item) => {
      let totalAmouts = 0;
      let advancePayments = 0;
      let companyName = "";
      let receiptNo = "";
      let receiptDate;
      (bills ?? []).filter((it) => it.projectName === item)
        .map((it) => {
          totalAmouts += toNumber(it.totalAmout);
          advancePayments += toNumber(it.advancePayment);
          companyName = it.companyName ?? "";
          receiptNo = it.receiptNo ?? "";
          receiptDate = it.receiptDate
        });

      return {
        projectName: item,
        receiptNo: receiptNo,
        companyName: companyName,
        totalAmout: totalAmouts,
        advancePayment: advancePayments,
        receiptDate: receiptDate
      }
    })

    setPlanReceivings(divs);
  }

  return <>
    <MDBRow>
      <MDBCol sm="3">
        <MDBBtn onClick={() => setOpenCreateModal(true)}><MDBIcon fas icon="plus" /> New a Contract</MDBBtn>
      </MDBCol>
      <MDBCol sm="9" className="displayEnd">
        <lable className="displayEnd">One thousand in Vietnamese (1000 VND)</lable>
      </MDBCol>
    </MDBRow>
    {openCreateModal && <CreateNewContract
      onCloseModal={() => setOpenCreateModal(false)}
      onSave={(data) => handleSaveData(data)}
    />}

    {open && <InputContractAmount phaseInfo={planAmount}
      onCloseModal={() => setOpen(false)}
      onSave={(data) => handleSaveReceipt(data)} />}
      
    <MDBAccordion initialActive={1}>
      <MDBAccordionItem collapseId={2} headerTitle={"View by projects"} style={{ marginTop: 25 }}>
        <div className="ag-theme-quartz" style={{ height: "65vh" }}>
          <AgGridReact
            rowData={orderBy(planReceivings, "name")}
            columnDefs={colDefReceivings}
            fullWidthCellRenderer
            onGridReady={onGridReady}
          />
        </div>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={"View by clients"}>
        <div className="ag-theme-quartz" style={{ height: "65vh" }}>
          <AgGridReact
            rowData={orderBy(clientReceivings, "name")}
            columnDefs={colDefClients}
            fullWidthCellRenderer
            onGridReady={onGridReady}
          />
        </div>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={1} headerTitle={"View by phases"}>
        <div className="ag-theme-quartz" style={{ height: "65vh" }}>
          <AgGridReact
            rowData={orderBy(bills, "name")}
            columnDefs={colDefs}
            fullWidthCellRenderer
            onGridReady={onGridReady}
          />
        </div>
      </MDBAccordionItem>
    </MDBAccordion>

    <ToastContainer />
  </>;
}

export default Contracts;
