import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import CtrStatusInfo from "../pages/Projects/CtrStatusInfo";
import "react-datepicker/dist/react-datepicker.css";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";
import { newGuid } from "../utils/utilities";

function CreatePhase({
  projectId,
  projectName,
  stsName,
  statuses,
  onCloseModal,
  onSave,
}) {
  const navigate = useNavigate();
  const [phaseName, setPhaseName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statusIds, setStatusIds] = useState();
  const [statusFinish, setStatusFinish] = useState(4);
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState();
  const [statusName, setStatusName] = useState(stsName ?? "To do");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    setStatusName("To do");
  }, []);

  useEffect(() => {
    let ids = (statuses ?? []).map((st) => st?.id);
    setStatusIds(ids);
    setStatusName("To do");
  }, [statuses]);

  useEffect(() => {
    if (stsName?.length > 0) setStatusName(stsName);
    else setStatusName("To do");
  }, [stsName]);

  const saveData = () => {

    if (phaseName?.length < 1) return;
    if ((statusIds ?? []).length < 1) return;
    let st = statusName ?? "To do";
    if (st?.length < 1) return

    let data = checked ? {
      statusFinish: statusFinish,
      name: phaseName,
      statusIds: statusIds,
      startDate: startDate,
      endDate: endDate,
      priorityNumber: priorityNumber,
      projectId: projectId,
      statusName: st,
      description: description,
    } : {
      statusFinish: statusFinish,
      name: phaseName,
      statusIds: statusIds,
      startDate: startDate,
      priorityNumber: priorityNumber,
      projectId: projectId,
      statusName: st,
      description: description,
    }

    onSave(data);
    onCloseModal();
  };

  const handleChangeStatusIds = (ids) => {
    setStatusIds(ids);
  }

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          value={phaseName}
          onChange={(evt) => setPhaseName(evt.target.value)}
        />

        <CtrPlanCompleted
          startDate={startDate}
          endDate={endDate}
          onSaveStartDate={(date) => setStartDate(date)}
          onSaveEndDate={(date) => setEndDate(date)}
          onSaveChecked={(isSelected) => setChecked(isSelected)}
        />

        {stsName?.length < 1 && <CtrStatusInfo
          statuses={statuses ?? []}
          curStatusName={statusName}
          curStatusFinish={statusFinish}
          curStatusIds={statusIds}
          onSaveStatusName={(name) => setStatusName(name)}
          onSaveStatusIdFinish={(id) => setStatusFinish(id)}
          onSaveStatusIds={(ids) => handleChangeStatusIds(ids)}
          onlyStatus
        />}

        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="6" sm="6">
            <MDBInput
              className="mb-3"
              type="number"
              label="Priority"
              value={priorityNumber}
              style={{ padding: 5 }}
              onChange={(evt) => setPriorityNumber(evt.target.value)}
            />
          </MDBCol>
        </MDBRow>
        <MDBTextArea
          className="mb-3"
          label="Description"
          id={`txt${newGuid()}`}
          rows="{4}"
          value={description ?? ""}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>New Phase of {projectName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="divScroll50Vh">
              {renderBody()}
            </div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}>Create a New Phase</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default CreatePhase;
