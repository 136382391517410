import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyBoard from "../../components/EmptyBoard";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import ProjectHeader from "./ProjectHeader";
import MainBoard from "./MainBoard";
import Loading from "../../components/Loading";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhaseCheckList from "./PhaseCheckList";

import CreatePhase from "../../modals/CreatePhase";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import CreateNewTask from "../../modals/CreateNewTask";
import boardsSlice from "../../redux/boardsSlice";
import { userRoles } from "../../utils/utilities";
import { orderBy } from "lodash";
import { HubConnectionBuilder } from "@microsoft/signalr";

function Projects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [project, setProject] = useState();
  const [phase, setPhase] = useState();
  const [isProject, setIsProject] = useState(true);
  const [phaseInfo, setPhaseInfo] = useState();
  const [taskInfo, setTaskInfo] = useState();
  const [taskInfoId, setTaskInfoId] = useState();
  const [showCheckList, setShowCheckList] = useState(false);
  const [projectInfo, setProjectInfo] = useState();
  const [staffsOfPhase, setStaffsOfPhase] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectMenus, setProjectMenus] = useState();
  const [teams, setTeams] = useState();
  const [phaseId, setPhaseId] = useState();
  const [phaseName, setPhaseName] = useState();
  const [projectId, setProjectId] = useState();
  const [statusOfPhase, setStatusOfPhase] = useState();
  const [statusOfTask, setStatusOfTask] = useState();
  const [columnInfo, setColumnInfo] = useState();
  const [checkListOfPhase, setCheckListOfPhase] = useState();
  const [allCheckListOfPhase, setAllCheckListOfPhase] = useState();
  const [allCheckList, setAllCheckList] = useState(false);
  const [allNotify, setAllNotify] = useState([]);
  const [staffs, setStaffs] = useState();
  const boards = useSelector((state) => state.boards);
  const activeBoard = boards.find((board) => board.isActive);
  if (!activeBoard && boards.length > 0)
    dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));

  const [statuses, setStatuses] = useState();
  const [userRole, setUserRole] = useState();
  const [myUserId, setMyUserId] = useState(0);
  const [myTeamId, setMyTeamId] = useState(0);
  const [phaseShortInfo, setPhaseShortInfo] = useState();
  const [projectResources, setProjectResources] = useState();
  const [dataToday, setDataToday] = useState([]);
  const [curTeams, setCurTeams] = useState([]);
  const [openPhaseModal, setOpenPhaseModal] = useState(false);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openSubTaskModal, setOpenSubTaskModal] = useState(false);
  const [stsName, setStsName] = useState("");
  const [selectedResources, setSelectedResources] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Multiple projects";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    setMyTeamId(+infoObj.teamId);
    setMyUserId(+infoObj.id);
  }, []);

  // //, 10000, 60000
  // useEffect(() => {
  //   const accessToken = window.localStorage.getItem("accessToken") ?? "";
  //   const connection = new HubConnectionBuilder()
  //     .withUrl(`${apiEndpoint.hosting}/notifications`, {
  //       accessTokenFactory: () => accessToken,
  //       withCredentials: false,
  //     })
  //     .withAutomaticReconnect([2000, 10000])
  //     .build()

  //   // start connection
  //   connection.start().then(() => {
  //     console.log("[Projects] Connection started")
  //   });

  //   connection.on("SendNotificationToUser",
  //     (userId, message, data) => {
  //       //console.log("[Projects] SendNotificationToUser", message);
  //       let phId = data?.phaseId ?? 0;
  //       let msgNotify = data?.msgNotify ?? "";
  //       let staffIds = data?.staffIds ?? []

  //       let hasUser = +userId === +myUserId;
  //       if (!hasUser) {
  //         hasUser = (staffIds ?? []).filter((it) => +it === +userId)?.length > 0;
  //       }

  //       let hasNotify = (allNotify ?? [])
  //         .filter((it) => (it?.phaseId === phId || it?.phaseId === phaseId) && msgNotify === it?.msgNotify)
  //         ?.length > 0

  //       if (!hasNotify && hasUser) {
  //         setAllNotify(data);
  //         if ((projectId ?? 0) > 0) {
  //           reloadProjectInfo(projectId)
  //         } else if ((phaseId ?? 0) > 0) {
  //           reloadPhaseInfo(phaseId);
  //         } else if ((phId ?? 0) > 0) {
  //           reloadPhaseInfo(phId);
  //         }
  //       }
  //     }
  //   );

  //   connection.on("SendNotificationToUsers",
  //     (userIds, message, data) => {
  //       //console.log("[SendNotificationToUsers]", data, message);
  //       let phId = data?.phaseId ?? 0;
  //       let msgNotify = data?.msgNotify ?? "";
  //       let staffIds = data?.staffIds ?? []

  //       let hasUser = (userIds ?? []).filter((it) => +it === +myUserId)?.length > 0;
  //       if (!hasUser) {
  //         hasUser = (staffIds ?? []).filter((it) => +it === +myUserId)?.length > 0;
  //       }

  //       let hasNotify = (allNotify ?? [])
  //         .filter((it) => (it?.phaseId === phId || it?.phaseId === phaseId) && msgNotify === it?.msgNotify)
  //         ?.length > 0

  //       if (!hasNotify && hasUser) {
  //         setAllNotify(data);
  //         if ((projectId ?? 0) > 0) {
  //           reloadProjectInfo(projectId)
  //         } else if ((phaseId ?? 0) > 0) {
  //           reloadPhaseInfo(phaseId);
  //         } else if ((phId ?? 0) > 0) {
  //           reloadPhaseInfo(phId);
  //         }
  //       }
  //     }
  //   );
  // }, [])

  useEffect(() => {
    setIsLoading(true);
    reloadAllListOfTeams();
    reloadAllListOfStatus();
    reloadAllListOfMenus();
    loadDataToday();
    reloadAllTeams();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    reloadAllCheckListOfPhase(phaseId);
  }, [phaseId]);


  useEffect(() => {
    loadDataStaffs(userRole)
  }, [userRole]);

  const loadDataStaffs = (uRole) => {
    let endpoint = `${apiEndpoint.hosting}/Teams/My-Team`;
    if (uRole === userRoles.Admin) endpoint = `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/All-Users`;

    axios
      .get(endpoint, configHeader())
      .then((result) => {
        setStaffs(orderBy(result?.data, "isActive", "desc"));
      });
  };

  const handleSavePhaseResource = (phId, data) => {
    phId &&
      axios
        .put(
          `${apiEndpoint.hosting}/Teams/Phases/${phId}/Resources`, data,
          configHeader()
        )
        .then(() => {
          reloadPhaseInfo(phId);
          toast.success("Assign resources to phase was successful");
        });
  }

  const reloadAllCheckListOfPhase = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/CheckLists/ViewByPhases/${phaseId}`,
          configHeader()
        )
        .then((result) => {
          let data = result?.data ?? [];
          let items = data.filter((it) => it?.teamId === +myTeamId) ?? [];
          setAllCheckListOfPhase(data);
          setCheckListOfPhase(items);
        });
  };

  const reloadAllListOfStatus = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/All-Status`,
        configHeader()
      )
      .then((result) => {
        setStatuses(result?.data ?? []);
      });
  };

  const reloadAllListOfTeams = () => {
    let proid = projectId ?? phaseInfo?.projectId ?? 0
    proid && axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${proid}/Teams`,
        configHeader()
      )
      .then((result) => {
        setTeams(result?.data ?? []);
      });
  };

  const reloadAllTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/Teams`,
        configHeader()
      )
      .then((result) => {
        let dataResults = result?.data?.dataResults ?? []
        setCurTeams(dataResults);
      });
  }

  useEffect(() => {
    reloadAllListOfTeams();
  }, [projectId ?? phaseInfo?.projectId]);

  const reloadAllListOfMenus = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/Menu-Projects`,
        configHeader()
      )
      .then((result) => {
        setProjectMenus(result?.data ?? []);
      });
  };

  useEffect(() => {
    reloadPhaseInfo(phaseId);
    reloadStaffsOfPhase(phaseId);
  }, [phaseId]);

  useEffect(() => {
    reloadProjectInfo(projectId);
    reloadProjectResources(projectId)
  }, [projectId]);

  const reloadProjectInfo = (projectId) => {
    projectId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${projectId}`,
          configHeader()
        )
        .then((result) => {
          setIsProject(true);
          setProjectInfo(result?.data);
        });
  };

  const reloadPhaseInfo = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}`,
          configHeader()
        )
        .then((result) => {
          setIsProject(false);
          setPhaseInfo(result?.data);
        });
  };

  useEffect(() => {
    setTaskInfo({});
    reloadTaskInfo(taskInfoId);
  }, [taskInfoId]);

  const reloadTaskInfo = (taskInfoId) => {
    taskInfoId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskInfoId}/ViewBoard`,
          configHeader()
        )
        .then((result) => {
          setTaskInfo(result?.data);
        });
  };

  const reloadStaffsOfPhase = (phaseId) => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/AllStaffs`,
          configHeader()
        )
        .then((result) => {
          setStaffsOfPhase(result?.data);
        });
  };

  const reloadProjectResources = (proId) => {
    proId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}/${proId}/Project-Resources`,
          configHeader()
        )
        .then((result) => {
          let items = result?.data ?? {};
          setProjectResources(items);
        });
  };

  const loadDataToday = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Today`,
        configHeader()
      )
      .then((result) => {
        let items = (result?.data ?? [])
          .filter((it) => it.taskId > 0 && it.statusName !== "Done" && (it.staffId ?? 0) === +myUserId) ?? [];
        setDataToday(items)
      });
  };

  const changeStatus = (isPM, curTaskId, curStatus, prevStatus) => {
    let isSubtasks = false;
    let urlChangeStt = apiEndpoint.taskUrl;
    if (isProject) urlChangeStt = apiEndpoint.phaseUrl;

    if (taskInfoId > 0) {
      urlChangeStt = `${apiEndpoint.taskUrl}/Subtasks`;
      isSubtasks = true;
    }

    if (taskInfoId < 1 && prevStatus === "Done") {
      toast.warning("Status is Done, you can't changes.");
      return;
    }

    if (!isPM && userRole !== userRoles.Admin && !isSubtasks) {
      toast.warning("You don't have permission changes.");
      return;
    }

    axios
      .put(
        `${apiEndpoint.hosting}/${urlChangeStt}/${curTaskId}/Status`,
        { status: curStatus },
        configHeader()
      )
      .then(() => {
        if (taskInfoId > 0) {
          setTaskInfo({});
          reloadTaskInfo(taskInfoId);
        } else if (isProject) reloadProjectInfo(projectId);
        else reloadPhaseInfo(phaseId);
        toast.success(`Status was changed to ${curStatus} successful.`);
      });
  };

  useEffect(() => {
    if (taskInfoId > 0) {
      setStatusOfTask(taskInfo?.statuses);
      setColumnInfo(taskInfo?.columns ?? []);
    } else if (isProject) {
      setColumnInfo(projectInfo?.columns ?? []);
    }
    else setColumnInfo(phaseInfo?.columns ?? []);
  }, [taskInfoId, taskInfo, isProject, projectInfo, phaseInfo]);

  const handleChangeProject = (pro) => {
    setPhase({});
    setPhaseName("");
    setPhaseId(0);
    setTaskInfoId(0);
    setTaskInfo({});
    setProject(pro);
    setProjectId(pro?.id);
    setIsProject(true);
  };

  const handleChangePhase = (pro, pha) => {
    setPhase(pha);
    setPhaseName(pha?.name);
    setPhaseId(pha?.id);
    setTaskInfoId(0);
    setTaskInfo({});
    setProject(pro);
    setProjectId(pro?.id);
    setIsProject(false);
  };

  const handleSelectTaskInfo = (pro, phInfo, info) => {
    setTaskInfoId(info?.id);
    setTaskInfo(info);
    setPhase(phInfo);
    setPhaseName(phInfo?.name);
    setPhaseId(phInfo?.id);
    setProject(pro);
    setProjectId(pro?.id);
    setIsProject(false);
  };

  const handleSavePhase = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllListOfMenus();
        reloadProjectInfo(projectId);
        toast.success("Your phase has been created.");
      });
  };

  const onClonePhase = (phaseId, data) => {
    phaseId &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/Clone`,
          data,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          toast.success("Your phase has been cloned successfully.");
        });
  };

  const handleDelete = (phaseId) => {
    phaseId &&
      axios
        .delete(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}`,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          toast.success("Your phase has been deleted.");
        });
  };

  const handleDeleteSubTask = (subTaskId) => {
    subTaskId &&
      axios
        .delete(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/Subtasks/${subTaskId}`,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          reloadTaskInfo(taskInfoId);
          toast.success("Your subtask has been deleted.");
        });
  };

  const handleDeleteTask = (taskInfoId) => {
    taskInfoId &&
      axios
        .delete(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskInfoId}`,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          reloadPhaseInfo(phaseId);
          toast.success("Your task has been deleted.");
        });
  };

  const handleSaveBasePhase = (id, data) => {
    setIsLoading(true);
    id &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${id}/BasicInfo`,
          data,
          configHeader()
        )
        .then(() => {
          reloadAllListOfMenus();
          reloadProjectInfo(projectId);
          setIsLoading(false);
          toast.success("Your phase has been updated.");
        });
  };

  const handleSaveTask = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        try {
          reloadPhaseInfo(phaseId);
          reloadAllListOfMenus();
          setIsLoading(false);
        } catch (err) {
          toast.error("Something went wrong");
        }
        toast.success("Your task has been created.");
      });
  };

  const onUpdateTask = (curPhaseId, data) => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllListOfMenus();
        curPhaseId && reloadPhaseInfo(curPhaseId);
        data?.id && reloadTaskInfo(data?.id);
        toast.success("Your task has been updated.");
      });
  };

  const onAddRevisionTask = (curPhaseId, data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${data?.id}/Revision`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllListOfMenus();
        reloadPhaseInfo(phaseId);
        curPhaseId && reloadPhaseInfo(curPhaseId);
        data?.id && reloadTaskInfo(data?.id);
        toast.success("Your task has been added new revision.");
      });
  };

  const handleLogWork = (taskId) => {
    setTaskInfo({});
    reloadAllListOfMenus();    
    reloadTaskInfo(taskId);
  }

  const handleAddSubtask = (taskId, data) => {
    taskId &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskId}/Subtask`,
          data,
          configHeader()
        )
        .then(() => {
          reloadPhaseInfo(phaseId);
          reloadTaskInfo(taskId);
        });
  };

  const handleUpdateSubtask = (subTaskId, data) => {
    subTaskId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/Subtasks/${subTaskId}`,
          data,
          configHeader()
        )
        .then(() => {
          reloadPhaseInfo(phaseId);
        });
  };

  const handleShowCheckListOfPhase = (data) => {
    setShowCheckList(true);
    setPhaseShortInfo(data);
  };

  const handleShowAllCheckListOfPhase = (data) => {
    setShowCheckList(true);
    setPhaseShortInfo(data);
    setAllCheckList(true)
  }

  const onSaveProject = (data) => {
    setIsLoading(true);
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        toast.success("Your project has been added successful.");
        reloadAllListOfMenus();
        reloadProjectInfo(projectId);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    phaseId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/AllStatus`,
          configHeader()
        )
        .then((result) => {
          setStatusOfPhase(result?.data);
        });
  }, [phaseId]);

  const handleSaveCheckList = (phaseId, data, targetChecked) => {
    if (targetChecked) activeCheckList(phaseId, data);
    else disableCheckList(phaseId, data);
  };

  const disableCheckList = (phaseId, data) => {
    let checkListId = data?.checkListId;
    (phaseId && checkListId) &&
      phaseId &&
      axios
        .put(
          `${apiEndpoint.hosting}/CheckLists/Phases/${phaseId}/Disable/${checkListId}`,
          data,
          configHeader()
        )
        .then(() => {
          reloadAllCheckListOfPhase(phaseId);
        })
        .catch(() => {
          toast.error(
            "Something went wrong, Check list is not yet disable, please try again later"
          );
        });
  };

  const activeCheckList = (phaseId, data) => {
    let checkListId = data?.checkListId;
    (phaseId && checkListId) &&
      axios
        .put(
          `${apiEndpoint.hosting}/CheckLists/Phases/${phaseId}/Active/${checkListId}`,
          data,
          configHeader()
        )
        .then(() => {
          reloadAllCheckListOfPhase(phaseId);
        })
        .catch(() => {
          toast.error(
            "Something went wrong, Check list is not active, please try again later"
          );
        });
  };

  const onSelectTodayTask = () => {
    //console.log(myUserId, "onSelectTodayTask", dataToday)
  }

  return (
    <div className=" overflow-hidden overflow-x-scroll" style={{ flex: "1" }}>
      <>
        {isLoading && <Loading />}
        {openTaskModal && (phaseId ?? 0) > 0 && (
          <CreateNewTask
            phaseId={phaseId}
            phaseName={phaseName}
            stsName={stsName}
            statuses={statusOfPhase ?? []}
            teams={(curTeams ?? []).filter((item) => item.isActive || +item.status === 1)}
            userRole={userRole}
            isPM={phase?.isPM}
            onCloseModal={() => setOpenTaskModal(false)}
            onSave={(data) => handleSaveTask(data)}
          />
        )}

        {openPhaseModal && (project?.id ?? 0) > 0 && (
          <CreatePhase
            projectId={project?.id}
            projectName={project?.name}
            stsName={stsName}
            statuses={statuses ?? []}
            onCloseModal={() => setOpenPhaseModal(false)}
            onSave={(data) => handleSavePhase(data)}
          />
        )}

        {openSubTaskModal && (
          <CreateNewSubTask
            taskId={taskInfo?.taskId}
            teamId={myTeamId}
            stsName={stsName}
            taskName={taskInfo?.taskName}
            staffs={staffsOfPhase ?? []}
            isPM={taskInfo?.isPM}
            projectResources={selectedResources}
            onCloseModal={() => setOpenSubTaskModal(false)}
            onSave={(data) => handleAddSubtask(taskInfo?.taskId, data)}
          />
        )}

        {showCheckList && (allCheckList ? (allCheckListOfPhase ?? []) : (checkListOfPhase ?? [])).length > 0 && (
          <PhaseCheckList
            open={showCheckList}
            phaseInfo={phaseShortInfo}
            teamId={myTeamId}
            checkListOfPhase={allCheckList ? allCheckListOfPhase : checkListOfPhase ?? []}
            handleOnClose={() => setShowCheckList(false)}
            saveData={(phaseId, data, targetChecked) =>
              handleSaveCheckList(phaseId, data, targetChecked)
            }
          />
        )}
        
        {(boards ?? []).length > 0 ? (
          <>
            <ProjectHeader
              setIsBoardModalOpen={setIsBoardModalOpen}
              isBoardModalOpen={isBoardModalOpen}
              project={project}
              phaseName={isProject ? "" : phaseName}
              phaseId={phaseId}
              phase={phase}
              statuses={statuses ?? []}
              teams={teams ?? []}
              staffsOfPhase={staffsOfPhase ?? []}
              userRole={userRole}
              statusOfPhase={statusOfPhase}
              taskInfo={taskInfo}
              onSavePhase={(data) => handleSavePhase(data)}
              onSaveTask={(data) => handleSaveTask(data)}
              handleAddSubtask={(id, data) => handleAddSubtask(id, data)}
              reloadPhaseInfo={reloadPhaseInfo}
              reloadTaskInfo={reloadTaskInfo}
              //allNotify={allNotify ?? []}
              handleCreatePhaseClick={() => setOpenPhaseModal(true)}
              handleCreateTaskClick={() => setOpenTaskModal(true)}
              handleCreateSubTaskClick={() => setOpenSubTaskModal(true)}
              onSelectProject={(pro) => handleChangeProject(pro)}
              onSelectPhase={(pro, phaseInfo) => handleChangePhase(pro, phaseInfo)}
            />

            <MainBoard
              setIsBoardModalOpen={setIsBoardModalOpen}
              isBoardModalOpen={isBoardModalOpen}
              columns={columnInfo}
              projects={projectMenus ?? []}
              statuses={statuses ?? []}
              projectId={projectId}
              currentPhaseId={phaseId}
              statusOfPhase={statusOfPhase}
              userRole={userRole}
              isProject={isProject}
              teams={teams ?? []}
              staffs={staffs}
              staffsOfPhase={staffsOfPhase ?? []}
              projectResources={projectResources ?? []}
              onSelectProject={(pro) => handleChangeProject(pro)}
              onSelectPhase={(pro, phInfo) => handleChangePhase(pro, phInfo)}
              onSelectTask={(pro, phInfo, item) => handleSelectTaskInfo(pro, phInfo, item)}
              changeStatus={changeStatus}
              onSavePhase={(id, data) => handleSaveBasePhase(id, data)}
              handleAddSubtask={(id, data) => handleAddSubtask(id, data)}
              handleUpdateSubtask={(id, data) => handleUpdateSubtask(id, data)}
              onSaveProject={(data) => onSaveProject(data)}
              onSaveTask={(data) => handleSaveTask(data)}
              handleDelete={handleDelete}
              onUpdateTask={onUpdateTask}
              handleDeleteTask={handleDeleteTask}
              onClonePhase={onClonePhase}
              statusOfTask={statusOfTask}
              taskInfoId={taskInfoId}
              taskInfo={taskInfo}
              selectedProject={project}
              selectedPhase={phase}
              phaseName={isProject ? "" : phaseName}
              handleDeleteSubTask={handleDeleteSubTask}
              reloadPhaseInfo={reloadPhaseInfo}
              onShowCheckListOfPhase={(phaseInfo) =>
                handleShowCheckListOfPhase(phaseInfo)
              }
              onShowAllCheckListOfPhase={(phaseInfo) =>
                handleShowAllCheckListOfPhase(phaseInfo)
              }
              handleLogWork={handleLogWork}
              reloadTaskInfo={reloadTaskInfo}
              onAddRevisionTask={onAddRevisionTask}
              dataToday={dataToday ?? []}
              onSelectTodayTask={onSelectTodayTask}
              handleCreatePhaseClick={(stName) => {
                setStsName(stName);
                setOpenPhaseModal(true)
              }}
              handleCreateTaskClick={(stName) => {
                setStsName(stName);
                setOpenTaskModal(true);
              }}
              handleCreateSubTaskClick={(stName, projectResources) => {
                setStsName(stName);
                setSelectedResources(projectResources);
                setOpenSubTaskModal(true);
              }}
              handleSavePhaseResource={handleSavePhaseResource}
            />
          </>
        ) : (
          <>
            <EmptyBoard type="add" />
          </>
        )}
      </>

      <ToastContainer />
    </div>
  );
}

export default Projects;
