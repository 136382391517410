import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import CtrStatusInfo from "../pages/Projects/CtrStatusInfo";
import "./modal.css";
import { first } from "lodash";
import CtrPhaseInfo from "../pages/Projects/CtrPhaseInfo";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";
import { newGuid } from "../utils/utilities";

function CloneProject({ projectInfo, statuses, staffs, onCloseModal, onSave }) {

  const navigate = useNavigate();
  const [projectName, setProjectName] = useState(projectInfo?.name ?? "");
  const [statusName, setStatusName] = useState(projectInfo?.statusName ?? "");
  const [statusFinish, setStatusFinish] = useState(projectInfo?.statusFinish);
  const [statusIds, setStatusIds] = useState([1, 2, 3, 4, 5]);
  const [phaseInfo, setPhaseInfo] = useState(projectInfo?.phaseInfo ?? []);
  const [checked, setChecked] = useState(false);
  const [startDate, setStartDate] = useState(projectInfo?.startDate ?? new Date());
  const [endDate, setEndDate] = useState(projectInfo?.endDate ?? new Date());
  const [priorityNumber, setPriorityNumber] = useState(projectInfo?.priorityNumber ?? 1);
  const [description, setDescription] = useState(projectInfo?.description ?? "");

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  useEffect(() => {
    let ids = (statuses ?? []).map((st) => st?.id);
    setStatusIds(ids);
  }, [statuses]);

  useEffect(() => {
    setProjectName(`Clone ${projectInfo?.name}`);
    setStatusName(projectInfo?.statusName ?? "");
    setStatusFinish(projectInfo?.statusFinish);
    setPriorityNumber(projectInfo?.priorityNumber);
    setDescription(projectInfo?.description ?? "");
    setStartDate(projectInfo?.startDate ?? new Date());
    setEndDate(projectInfo?.endDate ?? new Date());
  }, [projectInfo]);

  const saveData = () => {
    if (projectName?.length < 1) return;

    let data = checked ? {
      name: projectName,
      startDate: startDate,
      endDate: endDate,
      priorityNumber: priorityNumber,
      description: description,
      statusFinish: statusFinish ?? 4,
      statusName: statusName ?? "Todo",
      statusIds: statusIds,
      phaseInfo: phaseInfo ?? []
    } : {
      name: projectName,
      startDate: startDate,
      priorityNumber: priorityNumber,
      description: description,
      statusFinish: statusFinish ?? 4,
      statusName: statusName ?? "Todo",
      statusIds: statusIds,
      phaseInfo: phaseInfo ?? []
    }

    onSave(data);
    onCloseModal();
  };

  const handleChange = (id, name, priorityNo) => {
    let items = (phaseInfo ?? []).filter((p) => p.id !== id) ?? [];
    let currentItems = (phaseInfo ?? []).filter((p) => p.id === id);
    let currentItem = first(currentItems ?? []);
    if (currentItem) {
      currentItem.name = name;
      currentItem.priorityNumber = priorityNo;
      items.push(currentItem);
    }

    setPhaseInfo(items)
  }

  const renderBody = () => {
    return (
      <>
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle="Project information">
            <MDBInput
              className="mb-3"
              type="text"
              label="Name"
              value={projectName}
              onChange={(evt) => setProjectName(evt.target.value)}
            />

            <CtrPlanCompleted
              startDate={startDate}
              endDate={endDate}
              onSaveStartDate={(date) => setStartDate(date)}
              onSaveEndDate={(date) => setEndDate(date)}
              onSaveChecked={(isSelected) => setChecked(isSelected)}
            />

            <MDBRow style={{ marginTop: 15 }}>
              <MDBCol size="3" sm="3">
                <MDBInput
                  className="mb-3"
                  type="number"
                  label="Priority"
                  value={priorityNumber}
                  style={{ padding: 5 }}
                  onChange={(evt) => setPriorityNumber(evt.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBTextArea
              className="mb-3"
              label="Description"
              id={`txt${newGuid()}`}
              rows="{4}"
              value={description ?? ""}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle="Project Status information">
            <CtrStatusInfo
              statuses={statuses ?? []}
              curStatusName={statusName}
              curStatusFinish={projectInfo?.statusFinish}
              curStatusNames={projectInfo?.statusNames}
              onSaveStatusName={(name) => setStatusName(name)}
              onSaveStatusIdFinish={(id) => setStatusFinish(id)}
              onSaveStatusIds={(ids) => setStatusIds(ids)}
            />
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={3} headerTitle="Phases information">
            {(projectInfo?.phaseInfo ?? []).map((p) => {
              return <CtrPhaseInfo
                phaseInfo={p}
                onSave={(id, name, priorityNo) => handleChange(id, name, priorityNo)} />
            })}
          </MDBAccordionItem>
        </MDBAccordion>


      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Clone Project {projectName}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="divScroll">{renderBody()}</div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}>Clone Project</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default CloneProject;
