import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import CtrStatusInfo from "../pages/Projects/CtrStatusInfo";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";
import { newGuid } from "../utils/utilities";

function CreateProject({ statuses, onCloseModal, onSave }) {
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statusIds, setStatusIds] = useState([1, 2, 3, 4, 5]);
  const [statusFinish, setStatusFinish] = useState(4);
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState();
  const [statusName, setStatusName] = useState("To do");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    setStatusName("To do");
  }, []);

  useEffect(() => {
    let ids = (statuses ?? []).map((st) => st?.id);
    setStatusIds(ids);
    setStatusName("To do");
  }, [statuses]);

  const saveData = () => {
    if (projectName?.length < 1) return;
    if ((statusIds ?? []).length < 1) return;
    let st = statusName ?? "To do";
    if (st?.length < 1) return
    
    let data = checked ? {
      statusFinish: statusFinish,
      name: projectName,
      statusIds: statusIds,
      startDate: startDate,
      endDate: endDate,
      priorityNumber: priorityNumber,
      statusName: st,
      description: description,
    } : {
      statusFinish: statusFinish,
      name: projectName,
      statusIds: statusIds,
      startDate: startDate,
      priorityNumber: priorityNumber,
      statusName: st,
      description: description,
    }

    onSave(data);
    onCloseModal();
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          value={projectName}
          onChange={(evt) => setProjectName(evt.target.value)}
        />

        <CtrPlanCompleted
          startDate={startDate}
          endDate={endDate}
          onSaveStartDate={(date) => setStartDate(date)}
          onSaveEndDate={(date) => setEndDate(date)}
          onSaveChecked={(isSelected) => setChecked(isSelected)}
        />

        <CtrStatusInfo
          statuses={statuses ?? []}
          curStatusName={statusName}
          curStatusFinish={statusFinish}
          onSaveStatusName={(name) => setStatusName(name)}
          onSaveStatusIdFinish={(id) => setStatusFinish(id)}
          onSaveStatusIds={(ids) => setStatusIds(ids)}
          onlyStatus
        />
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="6" sm="6">
            <MDBInput
              className="mb-3"
              type="number"
              label="Priority"
              value={priorityNumber}
              style={{ padding: 5 }}
              onChange={(evt) => setPriorityNumber(evt.target.value)}
            />
          </MDBCol>
        </MDBRow>
        <MDBTextArea
          className="mb-3"
          label="Description"
          id={`txt${newGuid()}`}
          rows="{4}"
          value={description ?? ""}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>New Project</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="divScroll50Vh">
              {renderBody()}
            </div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
            <MDBBtn onClick={() => saveData()}>Create a New Project</MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default CreateProject;
