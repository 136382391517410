import React, { useEffect, useState } from "react";

import {
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBNavbar,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import Avatar from "./Avatar";
import { useNavigate } from "react-router-dom";
import AdminMenu from "./AdminMenu";
import LeaderMenu from "./LeaderMenu";
import AccountantMenu from "./AccountantMenu";
import { minSizeWindowPC, userRoles } from "../../utils/utilities";

function Menu({ hasAvatar, allNotify }) {
  const navigate = useNavigate();
  const [userMenu, setUserMenu] = useState();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    let menus = defineUserMenu(infoObj.role);
    setUserMenu(menus);
  }, []);

  const defineUserMenu = (role) => {
    if (role === userRoles.Admin) {
      return <AdminMenu pcIcon={true} />
    }

    if (role === "Accountant") {
      return <AccountantMenu />
    }

    return <LeaderMenu />
  };

  //pcIcon={windowSize[0] >= minSizeWindowPC} 

  // const renderAlert = () => {
  //   if ((allNotify ?? []).length < 1) return <></>

  //   return <>
  //     <MDBIcon fas icon="bell" color="warning" onClick={() => navigate("/users/notifications")} />
  //     {`(${allNotify?.length})`}
  //   </>
  // }

  const renderMenuOnPC = () => {
    return (
      <>
        <MDBNavbar expand="lg" light bgColor="light" style={{ height: 46 }}>
          <MDBContainer fluid>
            <MDBNavbarNav right className="mb-2 mb-lg-0">
              <MDBNavbarItem active>
                <MDBNavbarLink
                  aria-current="page"
                  onClick={() => navigate("/home")}
                >
                  <MDBIcon fas icon="house-damage" style={{ marginRight: 15 }} />
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink onClick={() => navigate("/dashboard")}>
                  <MDBIcon fas icon="chart-line" style={{ marginRight: 15 }} />
                  Dashboard
                </MDBNavbarLink>
              </MDBNavbarItem>
              {userMenu}
            </MDBNavbarNav>
          </MDBContainer>
          {hasAvatar && <Avatar />}
        </MDBNavbar>
      </>
    );
  }

  const [openNavCentred, setOpenNavCentred] = useState(false);

  const renderMenuOnMobile = () => {
    return <>
      <MDBNavbar light bgColor="light" style={{ height: 26, zIndex: 20000 }} >
        <MDBContainer fluid>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarCenteredExample'
            aria-controls='navbarCenteredExample'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setOpenNavCentred(!openNavCentred)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar open={openNavCentred} center id='navbarCenteredExample'>
            <MDBNavbarNav right className="mb-2 mb-lg-0">
              <MDBNavbarItem active>
                <MDBNavbarLink
                  aria-current="page"
                  onClick={() => navigate("/home")}
                >
                  <MDBIcon fas icon="house-damage" style={{ marginRight: 15 }} />
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink onClick={() => navigate("/dashboard")}>
                  <MDBIcon fas icon="chart-line" style={{ marginRight: 15 }} />
                </MDBNavbarLink>
              </MDBNavbarItem>
              {userMenu}
            </MDBNavbarNav>
            {/* {hasAvatar && <Avatar />} */}
            {/* <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current='page' href='#'>
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#'>Link</MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag='a' className='nav-link'>
                    Dropdown
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link>Action</MDBDropdownItem>
                    <MDBDropdownItem link>Another action</MDBDropdownItem>
                    <MDBDropdownItem link>Something else here</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                  Disabled
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav> */}
          </MDBCollapse>
        </MDBContainer>

      </MDBNavbar>
    </>
  }

  return windowSize[0] >= minSizeWindowPC ? renderMenuOnPC() : renderMenuOnMobile();
}

export default Menu;
