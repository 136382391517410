import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { isNumber, orderBy } from "lodash";
import DisplayTaskProcessing from "../pages/Planning/DisplayTaskProcessing";
import "./modal.css";
import ViewSubtaskInfo from "../components/ViewSubtaskInfo";
import { cellStyleDone, cellStyleOverdue } from "../utils/utilities";
import DisplayUserAndName from "../components/DisplayUserAndName";

function ViewTeamMembers({
  taskName,
  teamId,
  teamName,
  taskDetailInfo,
  onCloseModal,
}) {
  const navigate = useNavigate();
  const [minRow, setMinRow] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  const colDefs = [
    {
      field: "name",
      filter: true,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      headerName: "Subtask"
    },
    {
      field: "description",
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
      width: 275,
      filter: true,
      autoHeight: true
    },
    {
      field: "estimateHours",
      width: 65,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "right",
        };
      },
      headerName: "Estimate"
    },
    {
      field: "taskId",
      headerName: "Processing",
      width: 215,
      cellRenderer: (row) => {
        return <DisplayTaskProcessing
          statusName={row?.data?.statusName}
          planHours={row?.data?.estimateHours}
          workingHours={row?.data?.normalHours}
          otHours={row?.data?.overTimeHours}
        />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      filter: true,
      width: 95,
      headerName: "Status"
    },
    {
      field: "assigned",
      filter: true,
      headerName: "Staff",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      cellRenderer: (row) => {
        return <DisplayUserAndName userName={row?.data?.userName} staffName={row?.data?.assigned} />
      },
    },
  ];

  const createSiteBar = () => {
    let ctrMin = minRow ? "" : <MDBIcon fas icon="minus-square"
      onClick={() => setMinRow(true)}
      title="Minimize view description"
      style={{ cursor: "pointer", marginRight: 25 }} />;

    let ctrExternal = !minRow ? "" : <MDBIcon
      fas
      icon="eye"
      title="External view description"
      onClick={() => setMinRow(false)}
      style={{ cursor: "pointer", marginRight: 25 }}
    />

    return <MDBRow>
      <MDBCol sm="9" />
      <MDBCol sm="3" className="displayEnd">
        {ctrMin} {ctrExternal}
      </MDBCol>
    </MDBRow>
  }

  const renderSubByUsers = (subTasks) => {
    if (subTasks?.length < 1) return <>Don't have any sub tasks</>;

    let items = (subTasks ?? []).filter(
      (it) =>
        (isNumber(it?.estimateHours ?? 0) +
          isNumber(it?.normalHours ?? 0) +
          isNumber(it?.overTimeHours ?? 0)) >
        0
    );

    return (
      <>
        <MDBAccordion initialActive={1}>
          <ViewSubtaskInfo subTasks={subTasks ?? []} />
          <MDBAccordionItem collapseId={2} headerTitle={"View by grid"}>
            {createSiteBar()}
            <div className="ag-theme-quartz" style={{ height: 215 }}>
              <AgGridReact
                rowData={orderBy(items ?? [], "priorityNumber")}
                columnDefs={colDefs}
              />
            </div>
          </MDBAccordionItem>
        </MDBAccordion>

      </>
    );
  };

  const renderBody = () => {
    let subTasks = (taskDetailInfo?.subTasks ?? []).filter(
      (t) => t.teamId === teamId
    );

    return (
      <>
        {renderSubByUsers(subTasks)}
      </>
    );
  };

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className=" truncate max-w-[300px]">
              {taskName}
            </MDBModalTitle>
            <MDBBadge
              pill
              className="mx-2"
              color="info"
              light
              style={{ alignItems: "flex-end", marginLeft: 25 }}
            >
              {teamName}
            </MDBBadge>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="divScroll">{renderBody()}</div>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onCloseModal}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ViewTeamMembers;
