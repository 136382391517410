import { MDBCol, MDBRow, MDBTextArea } from "mdb-react-ui-kit";
import { newGuid } from "../utils/utilities";

function DisplayMultiRows({ description }) {
  return <MDBRow>
    <MDBCol sm="12">
      <MDBTextArea
        id={`txt${newGuid()}`}
        rows="{6}"
        style={{ height: 175 }}
        value={description ?? ""}
        readOnly
        disabled
      />
    </MDBCol>
  </MDBRow>
}

export default DisplayMultiRows;
