import {
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import "./modal.css";
import DisplayUserAndName from "../components/DisplayUserAndName";
import { useState } from "react";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";
import { newGuid } from "../utils/utilities";
import { first } from "lodash";

function PhaseAddResource({ phaseInfo, resources, staffs, onSave, onClose }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [checked, setChecked] = useState(false);
  const [employees, setEmployees] = useState([]);

  const saveData = () => {
    let staffIds = (employees ?? []).map((item) => item.id);
    if (staffIds?.length < 1) return;
    
    let data = checked ? {
      staffIds: staffIds,
      startDate: startDate,
      endDate: endDate,
    } : {
      staffIds: staffIds,
      startDate: startDate
    };

    onSave(data);
  }

  const handleChangeChecked = (isChecked, staffId) => {
    if (isChecked) {
      let rs = (staffs ?? []).filter((p) => staffId === p.id) ?? [];
      let pro = first(rs) ?? {};
      let ids = (employees ?? []).map((it) => it.id) ?? [];
      if (ids.some((id) => id === staffId)) {
      } else {
        let items = employees ?? [];
        items.push(pro);
        setEmployees(items);
      }
    } else {
      let items = (employees ?? []).filter((p) => staffId !== p.id) ?? [];
      setEmployees(items);
    }
  }

  const renderHeaderPage = () => {
    if (phaseInfo?.estimateHours > 0)
      return (
        <MDBModalTitle>
          Add Resources to {phaseInfo?.name} ({phaseInfo?.estimateHours})
        </MDBModalTitle>
      );

    return <MDBModalTitle>Add Resources to {phaseInfo?.name}</MDBModalTitle>;
  };

  const renderCurrentResources = () => {
    if ((resources ?? []).length < 1) return <></>

    return <>
      {(resources ?? []).map((it) => {
        return <MDBRow style={{ marginTop: 5 }} >
          <MDBCol size="8" className=" truncate max-w-[300px]" title={it?.displayName}>
            <DisplayUserAndName userName={it?.userName} staffName={it?.displayName} />
          </MDBCol>
          <MDBCol size="4" className=" truncate max-w-[300px]" title={it?.team}>
            {it?.team}
          </MDBCol>
        </MDBRow>
      })}
      <hr style={{ marginBottom: 20 }} />
    </>
  }

  const renderNewResources = () => {
    if ((staffs ?? []).length < 1) return <></>

    let hasRow = false;

    return <>
      {(staffs ?? []).map((it) => {
        let rs = (resources ?? []).filter((p) => it.id === p.id) ?? [];
        if (rs?.length > 0) return <></>
        hasRow = true;
        return <MDBRow style={{ marginTop: 5 }} >
          <MDBCol size="1">
            <MDBCheckbox
              id={`chb${newGuid()}`}
              onChange={(e) => handleChangeChecked(e.target.checked, it?.id)}
            />
          </MDBCol>
          <MDBCol size="7" className=" truncate max-w-[300px]" title={it?.displayName}>
            <DisplayUserAndName userName={it?.userName} staffName={it?.displayName} />
          </MDBCol>
          <MDBCol size="4" className=" truncate max-w-[300px]" title={it?.team}>
            {it?.team}
          </MDBCol>
        </MDBRow>
      })}

      {hasRow && <hr style={{ marginBottom: 20 }} />}
    </>
  }

  const renderBody = () => {
    return <>
      {renderCurrentResources()}
      {renderNewResources()}
      <CtrPlanCompleted
        startDate={startDate}
        endDate={endDate}
        onSaveStartDate={(date) => setStartDate(date)}
        onSaveEndDate={(date) => setEndDate(date)}
        onSaveChecked={(isSelected) => setChecked(isSelected)}
      />
    </>
  }

  return <MDBModal
    open={true}
    onClose={onClose}
    tabIndex="-1"
    className="projectDialog"
  >
    <MDBModalDialog>
      <MDBModalContent>
        <MDBModalHeader>
          {renderHeaderPage()}
          <MDBBtn
            className="btn-close"
            color="none"
            onClick={onClose}
          ></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="divScroll50Vh">
            {renderBody()}

          </div>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={onClose}>
            Close
          </MDBBtn>
          <MDBBtn
            className="btn btn-outline-primary"
            onClick={() => saveData()}
          >
            <MDBIcon fas icon="save" /> Save resources to phase
          </MDBBtn>
        </MDBModalFooter>
      </MDBModalContent>
    </MDBModalDialog>
  </MDBModal>
}

export default PhaseAddResource;
